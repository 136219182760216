export const AddNotif = item => ({
    type: 'ADD_NOTIF',
    item
});

export const AddNotifs = items => ({
    type: 'ADD_NOTIFS',
    items
});

export const MarkAsRead = id => ({
    type: 'MARK_AS_READ',
    id
});

export const MarkAllAsRead = () => ({
    type: 'MARK_ALL_AS_READ',
});
export const ClearAll = () => ({
    type: 'CLEAR_ALL_NOTIFS',
});