import Axios from "axios";
import UserController from "./UserController";
import { APIPath, APIRoute } from "../helpers/Constants";
import { generateResponse } from "../helpers/Utils";

const AdminController = {
    getUsers,
    getMagicLinks,
    createMagicLinks,
    updateMagicLink,
    removeMagicLink,
    resendMagicLink,
    banUser,
    unBanUser,
    refreshModuleMarks,
};

async function getUsers(
    pageCount, 
    orderBy, 
    partialEmail, 
    partialFirstName = null,
    partialLastName = null,
    isBanned = null, 
    joinedAfter = null,
    lastLoggedInAfter = null,
) {
    const userHeaders = await UserController.getUserHeaders();
    const searchModel = {
        pageCount, 
        orderBy, 
        partialEmail, 
        partialFirstName,
        partialLastName,
        isBanned, 
        joinedAfter,
        lastLoggedInAfter,
    }
    return Axios.post(APIRoute + APIPath.ADMIN_GET_USERS, searchModel, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getMagicLinks(page, partialEmail){
    const userHeaders = await UserController.getUserHeaders();

    const searchModel = {
        page, 
        partialEmail, 
    }

    return Axios.post(APIRoute + APIPath.ADMIN_GET_MAGIC_LINKS, searchModel, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
} 

async function createMagicLinks(emails = [], moduleIds = [],) {
    const userHeaders = await UserController.getUserHeaders();
    const linksModel = {
        emails,
        role: null,
        moduleIds,
    };
    return Axios.post(APIRoute + APIPath.CREATE_MAGIC_LINKS, linksModel, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function updateMagicLink(id, moduleIds = []) {
    const userHeaders = await UserController.getUserHeaders();
    const linksModel = {
        id,
        role: null,
        moduleIds,
    };
    return Axios.post(APIRoute + APIPath.UPDATE_MAGIC_LINK, linksModel, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function removeMagicLink(id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.REMOVE_MAGIC_LINK + id, null, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}


async function resendMagicLink(id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.RESEND_MAGIC_LINK + id, null, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}


async function banUser(id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.BAN_USER + id, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function unBanUser(id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.UN_BAN_USER + id, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function refreshModuleMarks(id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.REFRESH_MARKS + id, null, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

export default AdminController;