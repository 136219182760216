import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Container, Typography, Grid, Stepper, Step, StepLabel, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { Colours } from '../../helpers/Colours';
import { push } from 'connected-react-router';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { Role } from '../../helpers/Constants';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    section: {
        padding: `60px ${theme.spacing(2)}px`,
        '& p, h6': {
            color: Colours.bodyText,
        },
        '&.grey-bg': {
            backgroundColor: Colours.bg_grey_1,
        },
        '& h2': {
            fontSize: 50,
            [theme.breakpoints.down('xs')]: {
                fontSize: 36,
            },
        },
    },
    title: {
        [theme.breakpoints.down("xs")]: {
            fontSize: 42,
        }
    },
    helpCard: {
        borderRadius: 0,
        padding: 16,
        backgroundColor: Colours.primary25,
        marginBottom: 16,
    },
    sampleIcon: {
        color: Colours.bodyText,
        padding: 0,
        marginBottom: 16,
        '&:last-child': {
            marginBottom: 0,
        }
    },
    stepper: {
        backgroundColor: 'transparent',
        marginBottom: 16,
        '& svg': {
            zIndex: 1,
            fill: 'white',
            '& path': {
                left:  5,
            }
        },
        '& .step-1 svg': {
            backgroundColor: Colours.bg_grey_2,
        },
        '& .step-2 svg': {
            backgroundColor: Colours.primary,
        },
        '& .step-3 svg': {
            backgroundColor: Colours.olive,
        },
        '& .step-4 svg': {
            backgroundColor: Colours.secondary,
        },
    },
    list: {
        color: Colours.bodyText,
        fontSize: 16,
        '& li': {
            marginBottom: 8,
        }
    }
}));

function Guide(props) {
    const { Auth, PushHistory } = props;
    const classes = useStyles();

    // check user is authed
    React.useEffect(() => {
        if (!Auth.isAuthenticated) {
            PushHistory('/');
        }
    }, [Auth, PushHistory]);

    return <>
        <div className={classes.section}>
            <Container maxWidth="sm">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h2" className={classes.title}>
                            How-to Guide
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" paragraph>
                            To complete the course, work through all sections included in these eight modules:
                        </Typography>
                        <ol className={classes.list}> 
                            <li><Typography variant="body1">Independence and Choices</Typography></li>
                            <li><Typography variant="body1">Earning</Typography></li>
                            <li><Typography variant="body1">Spending</Typography></li>
                            <li><Typography variant="body1">Planning and Budgeting</Typography></li>
                            <li><Typography variant="body1">Cash Forecasting</Typography></li>
                            <li><Typography variant="body1">Saving for the Future</Typography></li>
                            <li><Typography variant="body1">Borrowing and Managing Debt</Typography></li>
                            <li><Typography variant="body1">Financial Products</Typography></li>
                        </ol>
                        <Typography variant="body1" paragraph>
                            Sections can contain study material, challenges, issues for reflection or multiple choice questions.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <b>Study material</b> contains text in plain English, tips and graphics.<br/>
                            <b>Challenges</b> give you the chance to do calculations and work things out.<br/>
                            <b>Reflections</b> encourage you to think and write about selected issues.<br/>
                            <b>Multiple choice questions</b> test you on the topics covered.<br/>
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Each online section can take between 15 and 60 minutes to complete.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            You can track your progress, as the status of each module and section changes as follows:
                        </Typography>
                        <Stepper className={classes.stepper} orientation="vertical">
                            <Step completed={true}>
                                <StepLabel className="step-1" StepIconComponent={ClearIcon}>Not Started</StepLabel>
                            </Step>
                            <Step completed={true}>
                                <StepLabel className="step-2" StepIconComponent={EditIcon}>Started</StepLabel>
                            </Step>
                            <Step completed={true}>
                                <StepLabel className="step-3" StepIconComponent={DoneIcon}>Completed and Unmarked</StepLabel>
                            </Step>
                            <Step completed={true}>
                                <StepLabel className="step-4" StepIconComponent={DoneAllIcon}>Completed and Marked</StepLabel>
                            </Step>
                        </Stepper>
                        <Typography variant="body1">
                            <b>A certificate will be provided upon successful completion of the course.</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            component={Link}
                            to={(Auth.role ?? []).includes(Role.Admin) ? "/Admin" : "/Course"}
                        >
                            Go To Modules
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    </>;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Guide)

Guide.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
};