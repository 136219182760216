import React from 'react';
import * as signalR from "@microsoft/signalr";
import { Link } from 'react-router-dom';
import { Role, SIGNALRPath } from '../../helpers/Constants';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Badge, Divider, Paper, Tooltip, ListItemIcon } from '@material-ui/core';
import NotificationsController from '../../controllers/NotificationsController';
import moment from 'moment';
import { isNullOrUndefined } from '../../helpers/Utils';
import UserController from '../../controllers/UserController';
import { AddNotif, AddNotifs, MarkAsRead, MarkAllAsRead, ClearAll } from '../../stores/Actions/Notifications';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import { Web } from '@material-ui/icons';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        overflowY: 'auto',
        maxHeight: '100vh',
    },
    drawer: {
        flexShrink: 0,
    },
    appBar: {
        position: 'fixed',
        left: 0,
        height: '100%',
        width: 56,
        paddingTop: 16,
        paddingBottom: 16,
        [theme.breakpoints.down('xs')]: {
            width: `100%`,
            height: 56,
            padding: '0 16px',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        '&.hidden-nav': {
            display: 'none',
        },
    },
    menuButton: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'unset'
        }
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.down('xs')]: {
            marginTop: '0',
        },
    },
    content: {
        flexGrow: 1,
        minHeight: '100vh',
        paddingLeft: 56,
        '&.hidden-nav': {
            paddingLeft: 0 + ' !important',
            paddingTop: 0 + ' !important',
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
            paddingTop: 56,
        },
    },
    avatarButton: {
        minWidth: 'unset',
        borderRadius: 64,
        padding: 0,
        right: 0,
        top: 0,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 8,
        }
    },
    notifButton: {

    },
    notifWrapper: {
        maxHeight: 500,
        overflowY: 'auto',
    },
    notif: {
        position: 'relative',
        padding: '12px 16px',
        width: 260,
    },
    notifViewAll: {
        position: 'absolute',
        top: 14,
        right: 16,
    },
    permanentTools: {
        display: 'flex',
        flexDirection: 'column-reverse',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
            height: '100%',
            width: 'unset',
        }
    },
    desktopButton: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }
    }
}));

/**
 * TODO: refactor to separate SignalR concerns? 
 */
function NavMenu(props) {
    const { PushHistory, AddNotif, AddNotifs, MarkAsRead, MarkAllAsRead, ClearAllNotifs, Notifications, container } = props;
    const { userName, role, isAuthenticated } = props.LogIn;
    const isAdmin = role && role.includes(Role.Admin);
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [userMenuOpen, setUserMenuOpen] = React.useState(false);
    const [notifTrayOpen, setNotifTrayOpen] = React.useState(false);
    const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
    const [notifAnchorEl, setNotifAnchorEl] = React.useState(null);
    const [showBadge, setShowBadge] = React.useState(false);
    const [totalUnread, setTotalUnread] = React.useState(0);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [hubConnection, setHubConnection] = React.useState(null);

    // initialise data
    React.useEffect(() => {
        async function init() {
            const totalResponse = await NotificationsController.getUnreadCount();
            if (!totalResponse.hasError) {
                setTotalUnread(totalResponse.data);
                if (totalResponse.data > 0) {
                    setShowBadge(true);
                }
            }
            const notifsResponse = await NotificationsController.getTop();
            if (!notifsResponse.hasError) {
                AddNotifs(notifsResponse.data);
            }
        }
        if (isAuthenticated) {
            init();
        }
    }, [AddNotifs, isAuthenticated]);

    // intialise signalR
    React.useEffect(() => {
        async function cleanUpNotifications() {
            await hubConnection.stop();
            setHubConnection(null);
            ClearAllNotifs();
            console.log("Cleaned up notifs");
        }

        if (isAuthenticated && hubConnection === null) {
            const options = {
                accessTokenFactory: UserController.getToken
            };
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(SIGNALRPath.Notifications, options)
                .withAutomaticReconnect()
                .build();

            connection.on("MarkAsRead", MarkAsRead);
            connection.on("MarkAllAsRead", MarkAllAsRead);
            connection.on("NewNotification", AddNotif);
            connection.start().catch(err => console.error(err));
            setHubConnection(connection);
        }
        else if(!isAuthenticated && hubConnection !== null){
            cleanUpNotifications();
        }
    }, [AddNotif, MarkAsRead, MarkAllAsRead, hubConnection, isAuthenticated])

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
            setRedirectUrl(null);
        }
    }, [redirectUrl, PushHistory]);

    // notif count
    React.useEffect(() => {
        async function recalcUnread() {
            let unread = 0;
            const totalResponse = await NotificationsController.getUnreadCount();
            if (!totalResponse.hasError) {
                unread = totalResponse.data;
            }
            setTotalUnread(unread);
        }
        if (isAuthenticated) {
            recalcUnread();
        }
    }, [Notifications, isAuthenticated]);

    function getHiddenNavClass() {
        if (!isAuthenticated) {
            return 'hidden-nav';
        } 
        return '';
    }

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen);
        handleUserMenuClose();
        handleNotifTrayClose();
    }

    function handleUserMenuToggle(event) {
        setUserMenuOpen(!userMenuOpen);
        setProfileAnchorEl(event.currentTarget);
        handleNotifTrayClose();
    }

    function handleUserMenuClose() {
        setUserMenuOpen(false);
        setProfileAnchorEl(null);
    }

    function handleNotifTrayToggle(event) {
        if (!notifTrayOpen) {
            markUnreadAsRead();
            setShowBadge(false);
        }
        setNotifTrayOpen(!notifTrayOpen);
        setNotifAnchorEl(event.currentTarget);
        handleUserMenuClose();
    }

    function handleNotifTrayClose() {
        setNotifTrayOpen(false);
        setNotifAnchorEl(null);
    }

    function viewAllNotifs() {
        handleNotifTrayClose();
        setRedirectUrl('/Notifications');
    }

    function markUnreadAsRead() {
        NotificationsController.markAllAsRead();
        MarkAllAsRead();
        setTotalUnread(0);
    }

    function buildDrawerOption(name, route, icon, key = null) {
        return <ListItem
            button
            component={Link}
            to={route}
            key={key ?? name}
            onClick={handleDrawerToggle}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={name} />
        </ListItem>;
    }

    function buildDrawerOptions() {
        const drawerOptions = [
            buildDrawerOption('Home', '/', <HomeOutlinedIcon/>),
        ];
        if (userName) {
            drawerOptions.push(buildDrawerOption('Dashboard', isAdmin ? '/Admin' : '/Course', <ViewModuleIcon/>));
            drawerOptions.push(buildDrawerOption('Guide', '/Guide', <HelpOutlineIcon/>));
        } else {
            drawerOptions.push(buildDrawerOption('Login', '/Login', <VpnKeyOutlinedIcon/>));
        }
        if (isAdmin) {
            drawerOptions.push(buildDrawerOption('CMS', '/CMSModules', <Web/>));
        }
        return <List>{drawerOptions}</List>;
    }

    function buildProfileOptions() {
        return userName
            ? <>
                <Button
                    className={classes.avatarButton}
                    onClick={handleUserMenuToggle}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                >
                    <Avatar>
                        {userName.charAt(0).toUpperCase()}
                    </Avatar>
                </Button>
                <Menu
                    id="user-menu"
                    anchorEl={profileAnchorEl}
                    keepMounted
                    open={Boolean(userMenuOpen)}
                    onClose={handleUserMenuClose}
                >
                    <MenuItem onClick={handleUserMenuClose} component={Link} to='/UserManagement' key='userManagement'>
                        Profile
                    </MenuItem>
                    <MenuItem onClick={handleUserMenuClose} component={Link} to='/Logout' key='logout'>
                        Logout
                    </MenuItem>
                </Menu>
            </>
            : null;
    }

    function buildNotificationTray() {
        return userName
            ? <>
                <Tooltip title="Notifications" placement="right">
                    <IconButton
                        className={classes.notifButton}
                        onClick={handleNotifTrayToggle}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <Badge badgeContent={showBadge ? totalUnread : null} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Menu
                    id="notif-tray"
                    anchorEl={notifAnchorEl}
                    keepMounted
                    open={Boolean(notifTrayOpen)}
                    onClose={handleNotifTrayClose}
                    className={classes.notifWrapper}
                >
                    {[
                        <Paper elevation={0} key="title-paper" className={classes.notif}>
                            <Typography variant="h5">
                                Notifications
                            </Typography>
                            <Button
                                onClick={() => viewAllNotifs()}
                                size="small"
                                variant="outlined"
                                className={classes.notifViewAll}
                                disabled={Notifications.length === 0}
                            >
                                View All
                            </Button>
                        </Paper>,
                        <Divider key="title-divider" />,
                        buildNotifications(),
                    ]}
                </Menu>
            </>
            : null;
    }

    function buildNotifications() {
        if (Notifications.length === 0) {
            return <Paper elevation={0} key="no-notifs" className={classes.notif}>
                <Typography variant="body1" color="textSecondary">
                    No notifications
                </Typography>
            </Paper>
        }
        return <div key="notifs">
            {Notifications.map((e, i) => buildNotification(e, i))}
        </div>;
    }

    function buildNotification(notif, index) {
        const isLastIndex = index === Notifications.length - 1;
        const { title, message, created } = notif;
        return <div key={index}>
            <Paper elevation={0} className={classes.notif}>
                <Typography variant="body1" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {message}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    {moment.utc(created).local().format('D MMMM YYYY')}
                </Typography>
            </Paper>
            {!isLastIndex
                ? <Divider />
                : null}
        </div>;
    }

    return <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={`${classes.appBar} ${getHiddenNavClass()}`}>
            <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
            >
                <MenuIcon />
            </IconButton>
            <div className={classes.permanentTools}>
                {buildNotificationTray()}
                {buildProfileOptions()}
            </div>
            <Tooltip title="Dashboard" placement="right">
                <IconButton
                    color="inherit"
                    aria-label="Dashboard"
                    onClick={() => setRedirectUrl(isAdmin ? '/Admin' : '/Course')}
                    className={classes.desktopButton}
                >
                    <ViewModuleIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Guide" placement="right">
                <IconButton
                    color="inherit"
                    aria-label="Guide"
                    onClick={() => setRedirectUrl('/Guide')}
                    className={classes.desktopButton}
                >
                    <HelpOutlineIcon />
                </IconButton>
            </Tooltip>
            {isAdmin 
                ? <Tooltip title="CMS" placement="right">
                    <IconButton
                        color="inherit"
                        aria-label="CMS"
                        onClick={() => setRedirectUrl('/CMSModules')}
                        className={classes.desktopButton}
                    >
                        <Web />
                    </IconButton>
                </Tooltip>
                : null}
        </AppBar>
        <nav className={classes.drawer} aria-label="Mailbox folders">
            <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{ paper: classes.drawerPaper }}
                ModalProps={{ keepMounted: true }}
            >
                {buildDrawerOptions()}
            </Drawer>
        </nav>
        <main className={`${classes.content} ${getHiddenNavClass()}`}>
            {props.children}
        </main>
    </div>;
}

const mapStateToProps = state => ({
    LogIn: state.Authentication,
    Notifications: state.Notifications,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
    AddNotif: item => dispatch(AddNotif(item)),
    AddNotifs: items => dispatch(AddNotifs(items)),
    MarkAsRead: id => dispatch(MarkAsRead(id)),
    MarkAllAsRead: () => dispatch(MarkAllAsRead()),
    ClearAllNotifs: () => dispatch(ClearAll()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavMenu)

NavMenu.propTypes = {
    container: PropTypes.object,
    children: PropTypes.any,
    LogIn: PropTypes.object,
    PushHistory: PropTypes.func,
    AddNotif: PropTypes.func,
    AddNotifs: PropTypes.func,
    MarkAsRead: PropTypes.func,
    MarkAllAsRead: PropTypes.func,
    Notifications: PropTypes.array,
};