import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ModuleController from '../../controllers/ModuleController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { isNullOrUndefined, isNullOrWhitespace } from '../../helpers/Utils';
import { makeStyles, Typography, Grid, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox, InputAdornment } from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { SpacedContainer } from '../../components/Common/SpacedContainer';
import { Alert } from '../../components/Common/Alert';
import { ContentType, QuestionType, QuestionRenderType, CompletionState, QuestionRenderFlags } from '../../helpers/Constants';
import { Colours } from '../../helpers/Colours';
import { GreyCard } from '../../components/Common/GreyCard';

const useStyles = makeStyles(theme => ({
    contentWrapper: {
        marginBottom: 64,
    },
    navButtons: {
        position: 'fixed',
        padding: "16px !important",
        top: 0,
        left: 56,
        maxWidth: 382,
        minWidth: 382,
        [theme.breakpoints.down("lg")]: {
            maxWidth: 'unset',
            minWidth: 'unset',
            left: 0,
            padding: "8px !important",
            position: 'relative',
        }
    },
    mainTitle: {
        [theme.breakpoints.down("xs")]: {
            fontSize: 32,
        }
    },  
    image: {
        width: '100%',
        WebkitBoxShadow: '0px 0px 6px -1px rgba(0,0,0,0.48)',
        boxShadow: '0px 0px 6px -1px rgba(0,0,0,0.48)',
    },
    content: {
        fontSize: 22,
        color: Colours.bodyText,
        [theme.breakpoints.down("xs")]: {
            fontSize: 16,
        },
        '& pre' : {
            padding: 32,
            whiteSpace: 'normal',
            fontFamily: 'Roboto',
            backgroundColor: Colours.bg_grey_1,
            [theme.breakpoints.down("xs")]: {
                padding: 16,
            },
            '& p:first-child': {
                marginTop: 0,
            }
        }
    },
    question: {
        marginTop: 32,
        marginBottom: 32,
    },
    subtitle: {
        marginBottom: 64,
        marginTop: 64,
        [theme.breakpoints.down("xs")]: {
            fontSize: 26,
        },
    },
}));

function CourseSubmodule(props) {
    const { module, submodule, name, parent, state } = queryString.parse(props.location.search);
    const { Auth, PushHistory } = props;
    const [moduleItems, setModuleItems] = React.useState([]);
    const [moduleQuestions, setModuleQuestions] = React.useState([]);
    const [confirmDone, setConfirmDone] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [savingQuestion, setSavingQuestion] = React.useState(null);
    const [savingError, setSavingError] = React.useState(null);
    const [warningText, setWarningText] = React.useState(null);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const classes = useStyles();

    const editable = state === CompletionState.InProgress.toString() 
        || state === CompletionState.NeedsReview.toString() 
        || state === CompletionState.NotStarted.toString();

    const fetchModuleData = React.useCallback(async () => {
        const moduleResponse = await ModuleController.getMyModuleItems(submodule);
        if (moduleResponse.hasError) {
            setWarningText(moduleResponse.data);
            return [];
        } else {
            setModuleItems(moduleResponse.data);
            return moduleResponse.data;
        }
    }, [submodule]);

    const fetchQuestionData = React.useCallback(async (data = []) => {
        const questionItems = data.filter(e => e.type === ContentType.Question);
        const output = [];
        for (const item in questionItems) {
            const { questionId } = questionItems[item];
            const questionResponse = await ModuleController.getMyModuleQuestion(module, questionId);
            if (questionResponse.hasError) {
                setWarningText(questionResponse.data);
            } else {
                output.push(questionResponse.data);
            }
        }
        setModuleQuestions(output);
    }, [module]);

    // initialise
    React.useEffect(() => {
        async function init() {
            setWarningText(null);
            setLoading(true);
            if (!Auth.isAuthenticated) {
                setRedirectUrl('/Login');
                return;
            }
            const data = await fetchModuleData();
            await fetchQuestionData(data);
            setLoading(false);
        }
        init();
    }, [Auth, fetchModuleData, fetchQuestionData]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [PushHistory, redirectUrl]);

    async function handleMarkComplete() {
        setLoading(true);
        setConfirmDone(false);
        let anySaveFailed = false;
        
        // first re-save all module question answers to ensure no data is lost
        for (let i = 0; i < moduleQuestions.length; i++) {
            const e = moduleQuestions[i];
            const success = await handleSaveQuestionAnswers(e.id);
            if (!success) {
                anySaveFailed = true;
            }
        }
        
        // if any save failed, don't set the module complete - the error dialog should appear afterwards
        if (anySaveFailed) {
            setLoading(false);
            return;
        }

        // then set module complete
        const response = await ModuleController.completeSubmodule(submodule);
        if (response.hasError) {
            setSavingError(response.data?.data ?? "Please contact an administrator");
        } else {
            setRedirectUrl(`/CourseModule?module=${module}&name=${parent}`);
        }
        setLoading(false);
    }

    function handleUpdateAnswer(event, question, answerId) {
        const { value, checked } = event.target;
        const { id, type } = question;
        switch (type) {
            case QuestionType.Text:
            case QuestionType.MultipleText:
                handleUpdateTextAnswer(value, answerId, id);
                break;
            case QuestionType.SingleChoice:
            case QuestionType.MultipleChoice:
                handleUpdateChoiceAnswer(checked, answerId, id, question.type);
                break;
            default:
                return null;
        }
    }

    function handleUpdateTextAnswer(value, answerId, questionId) {
        const question = moduleQuestions.filter(e => e.id === questionId)[0];
        if (isNullOrUndefined(question)) {
            return;
        }
        question.answers = question.answers.map(e => e.id === answerId ? { ...e , answerContent: value } : e);
        setModuleQuestions(moduleQuestions.map(e => e.id === questionId ? question : e));
    }

    function handleUpdateChoiceAnswer(value, answerId, questionId, type) {
        const question = moduleQuestions.filter(e => e.id === questionId)[0];
        if (isNullOrUndefined(question)) {
            return;
        }
        question.answers = question.answers.map(e => e.id === answerId 
            ? { ...e , selected: value, answerContent: "" } 
            : (value === true && type === QuestionType.SingleChoice)  ? { ...e, selected: false } : e);
        setModuleQuestions(moduleQuestions.map(e => e.id === questionId ? question : e));
    }

    async function handleSaveQuestionAnswers(questionId) {
        if (!isNullOrUndefined(savingQuestion)) {
            return false;
        }
        setSavingQuestion(questionId);
        const question = moduleQuestions.filter(e => e.id === questionId)[0];
        const isChoice = question.type === QuestionType.MultipleChoice || question.type === QuestionType.SingleChoice;
        if (isNullOrUndefined(question)) {
            return false;
        }
        const mappedAnswers = question.answers.filter(e => isChoice ? e.selected : true).map(e => ({ 
            answerId: e.id, 
            content: e.answerContent 
        }));
        const response = await ModuleController.submitAnswers(questionId, mappedAnswers);
        if (response.hasError) {
            setSavingError(response.data?.data ?? "Please contact an administrator");
        }
        setSavingQuestion(null);
        return true;
    }

    function buildModuleItem(moduleItem) {
        const { id, title } = moduleItem;
        return <Grid item key={id} xs={12}>
            {!isNullOrWhitespace(title) 
                ? <Typography variant="h4" className={classes.subtitle}>{title}</Typography>
                : null}
            {buildModuleItemContent(moduleItem)}
        </Grid>;
    }

    function buildModuleItemContent(moduleItem) {
        const { title, type, content } = moduleItem;
        switch (type) {
            case ContentType.Image:
                return <img className={classes.image} src={content + "?" + moment().get('time')} alt={title} />;
            case ContentType.Text:
                return <div className={classes.content} dangerouslySetInnerHTML={{ __html: content }}></div>
            case ContentType.Question:
                return buildModuleItemQuestion(moduleItem);
            default:
                return <Typography>INVALID MODULE ITEM DETECTED</Typography>;
        }
    }

    function buildModuleItemQuestion(moduleItem) {
        const question = moduleQuestions.filter(e => e.id === moduleItem.questionId)[0];
        if (isNullOrUndefined(question)) {
            return <Typography>INVALID QUESTION DETECTED</Typography>
        }
        
        const getQuestion = () => {
            const { type } = question;
            switch (type) {
                case QuestionType.Text:
                case QuestionType.MultipleText:
                    return buildTextQuestion(question);
                case QuestionType.SingleChoice:
                case QuestionType.MultipleChoice:
                    return buildChoiceQuestion(question);
                default: 
                    return null;
            }
        }

        return <>
            {getQuestion()}
            {buildFeedback(question.feedback, question.maximumMark)}
        </>;
    }

    function buildFeedback(questionFeedback, maximumMark) {
        if (isNullOrUndefined(questionFeedback)) {
            return null;
        }
        const { mark, feedback } = questionFeedback;
        return <GreyCard className={classes.question}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">Marks / Feedback</Typography>
                </Grid>
                {!isNullOrUndefined(feedback) && !isNullOrWhitespace(feedback)
                    ? <Grid item xs={12}>
                        <Typography variant="body1">{feedback}</Typography>
                    </Grid>
                    : null}
                {!isNullOrUndefined(mark) && mark > 0
                    ? <Grid item xs={12}>
                        <Typography className={classes.grade} variant="body1"><b>{mark} / {maximumMark}</b></Typography>
                    </Grid>
                    : null}
            </Grid>
        </GreyCard>;
    }

    function buildTextQuestion(question) {
        const { description, title, answers, id, type, maximumMark } = question;
        return <GreyCard className={classes.question}>
            <Grid container spacing={1}>
                {!isNullOrWhitespace(title)
                    ? <Grid item xs={12}>
                        <Typography variant="h5">{title}</Typography>
                    </Grid>
                    : null}
                {!isNullOrWhitespace(description)
                    ? <Grid item xs={12}>
                        <Typography variant="body1">{description}</Typography>
                    </Grid>
                    : null}
                {answers.map((e, i) => {
                    const { id: answerId, renderType, renderFlag, answerContent, content } = e;
                    const isMultiline = renderType === QuestionRenderType.Multiline ||renderType === QuestionRenderType.ExpandableMultiLine;
                    const isLargeMultiLine = renderType === QuestionRenderType.Multiline;
                    const isCell = renderType === QuestionRenderType.Cell;
                    const isDoubleCell = renderType === QuestionRenderType.DoubleColumnCell;
                    const isNumber = (renderFlag === QuestionRenderFlags.Number || renderFlag === QuestionRenderFlags.Currency || renderFlag === QuestionRenderFlags.Percentage );
                    const isCurrency = renderFlag === QuestionRenderFlags.Currency;
                    const isPercentage = renderFlag === QuestionRenderFlags.Percentage;
                    const isLast = i === answers.length - 1;
                    return <React.Fragment key={answerId}>
                        {isDoubleCell && isLast && i % 2 === 0
                            ? <Grid item xs={6}></Grid>
                            : null}
                        <Grid item md={isDoubleCell ? 6 : (isCell ? 4 : 12)} xs={(isCell || isDoubleCell) ? 6 : 12}>
                            <TextField
                                value={answerContent ?? ""}
                                onChange={(event) => handleUpdateAnswer(event, question, answerId)}
                                variant="filled"
                                type="text"
                                rows={isMultiline ? isLargeMultiLine ? 50: 4 : 1}
                                InputProps={isCurrency || isPercentage ? {
                                    startAdornment: <InputAdornment position="start">
                                        {isCurrency ? '£' : '' }
                                    </InputAdornment>,
                                    endAdornment:<InputAdornment position="start">
                                    {isPercentage? '%' : ''} 
                                </InputAdornment>
                                } : {}}
                                label={type === QuestionType.MultipleText ? content : "Your answer"}
                                fullWidth={!isNumber || isMultiline}
                                multiline={isMultiline}
                                disabled={!editable}
                            />
                        </Grid>
                    </React.Fragment>;
                })}
                <Grid item xs={12}>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        disabled={savingQuestion === id || !editable} 
                        onClick={() => handleSaveQuestionAnswers(id)}
                    >
                        Save Answer
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption">
                        {maximumMark}{maximumMark === 1 ? '' : ' total'} possible mark{maximumMark === 1 ? '' : 's'}
                    </Typography>
                </Grid>
           </Grid>
        </GreyCard>
    }

    function buildChoiceQuestion(question) {
        const { description, title, answers, id, maximumMark } = question;
        return <GreyCard className={classes.question}>
            <Grid container spacing={2}>
                {!isNullOrWhitespace(title)
                    ? <Grid item xs={12}>
                        <Typography variant="h5">{title}</Typography>
                    </Grid>
                    : null}
                {!isNullOrWhitespace(description)
                    ? <Grid item xs={12}>
                        <Typography variant="body1">{description}</Typography>
                    </Grid>
                    : null}
                {answers.map(e => {
                    const { id: answerId, content, selected } = e;
                    return <Grid key={answerId} item xs={12}>
                        <FormControlLabel
                            disabled={!editable}
                            control={
                                <Checkbox
                                    checked={selected}
                                    onClick={() => handleUpdateAnswer({ target: { checked: !selected } }, question, answerId)}
                                    color="secondary"
                                    disabled={!editable}
                                />
                            }
                            label={content}
                        />
                    </Grid>;
                })}
                <Grid item xs={12}>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        disabled={savingQuestion === id || !editable} 
                        onClick={() => handleSaveQuestionAnswers(id)}
                    >
                        Save Answer
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption">
                        {maximumMark}{maximumMark === 1 ? '' : ' total'} possible mark{maximumMark === 1 ? '' : 's'}
                    </Typography>
                </Grid>
           </Grid>
        </GreyCard>
    }

    function buildErrorDialog() {
        return <Dialog open={!isNullOrUndefined(savingError)} onClose={() => setSavingError(null)}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>{savingError}</DialogContent>
            <DialogActions>
                <Button onClick={() => setSavingError(null)}>OK</Button>
            </DialogActions>
        </Dialog>;
    }

    return <SpacedContainer maxWidth="md">
        <LoadingOverlay loading={loading}/>

        <Grid container spacing={2} className={classes.contentWrapper}>
            <Grid item xs={12}>
                <Typography variant="h2" className={classes.mainTitle} gutterBottom> 
                    {name}
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.navButtons}>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={5} xs={12}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setRedirectUrl(`/CourseModule?module=${module}&name=${parent}`)}
                            fullWidth
                        >
                            Back to Sections
                        </Button>
                    </Grid>
                    <Grid item xl={12} lg={5} xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setConfirmDone(true)}
                            disabled={!editable}
                            fullWidth
                        >
                            Mark as Done
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Alert severity="warning" text={warningText}/>
            </Grid>

            {!loading 
                ? moduleItems.map(buildModuleItem)
                : null}

            <Grid item md={6} xs={12}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setConfirmDone(true)}
                    disabled={!editable}
                    fullWidth
                >
                    Mark as Done
                </Button>
            </Grid>
        </Grid>

        <Dialog open={confirmDone} onClose={() => setConfirmDone(false)}>
            <DialogTitle>Mark as Done</DialogTitle>
            <DialogContent>
                <Alert severity="warning" header={"Warning"} text={"Once marked as done, you will not be able to edit this section again until it has been reviewed by an admin."}/>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => handleMarkComplete()}>Mark as Done</Button>
                <Button color="primary" onClick={() => setConfirmDone(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>

        {buildErrorDialog()}
    </SpacedContainer>;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
})
const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CourseSubmodule)

CourseSubmodule.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    location: PropTypes.object,
};
