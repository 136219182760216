import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export const PrivateRoute = ({ component: Component, roles, role, ...rest }) => (
    <Route {...rest} render={props => {
        // TODO: may need to reimplement some kind of check here
        // to see if the user's token is still valid and throw to
        // login screen if not? 

        // check if route is restricted by role
        if (roles && roles.some(r => role.includes(r))) {
            // authorized so return component
            return <Component {...props} />;
        }

        // else return home
        return <Redirect to={{ pathname: '/' }} />;
    }}/>
);

PrivateRoute.propTypes = {
    component: PropTypes.any,
    roles: PropTypes.array,
    role: PropTypes.array,
    location: PropTypes.object
};