export const Colours = {
    primary: '#6200EE',
    primary25: 'rgba(227, 184, 255, 0.25)',
    primary50: 'rgba(227, 184, 255, 0.5)',
    pink: '#e3b8ff',
    secondary: '#03DAC6',
    white: '#FFF',
    black: '#000',
    bodyText: 'rgba(0, 0, 0, 0.6)',
    bg_grey_1: '#DFDFDF', 
    bg_grey_2: '#AFAFAF', 
    red: '#FF0000',
    olive: '#01877A',
};
