import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import UserController from '../../controllers/UserController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { TextField, Button, Grid, Container, Typography, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';
import { isNullOrUndefined, checkPassword } from '../../helpers/Utils';
import { Alert } from '../../components/Common/Alert';
import { LogIn } from '../../stores/Actions/Authentication';
import { Colours } from '../../helpers/Colours';
import logoImg from '../../images/logo.svg';

const useStyles = makeStyles(theme => ({
    root:{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    innerWrapper:{
        border: `1px solid ${Colours.bg_grey_1}`,
        backgroundColor: Colours.white,
        padding: 36,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
            [theme.breakpoints.down("xs")]: {
                padding: 24,
            }
    },
    title:{
        fontWeight: 300,
        marginBottom: 36,
    },
    checkboxWrapper: {
        '& a': {
            color: Colours.primary,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            }
        },
    },
    fsLogo: {
        maxWidth: '100%',
        width: 175,
        marginBottom: 36,
    }
}));

function Register(props) {
    const { email, token } = queryString.parse(props.location.search);
    const [password, setPassword] = React.useState('');
    const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
    const [acceptsTerms, setAcceptsTerms] = React.useState(false);
    const [passwordRequirements, setPasswordRequirements] = React.useState(null);
    const [passwordError, setPasswordError] = React.useState(null);
    const [submitting, setSubmitting] = React.useState(false);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [warningText, setWarningText] = React.useState(null);
    const classes = useStyles();

    // initialise
    React.useEffect(() => {
        async function init() {
            const pwordReqResponse = await UserController.getPasswordRequirements();
            if (!pwordReqResponse.hasError) {
                setPasswordRequirements(pwordReqResponse.data);
            } else {
                setWarningText('Failed to fetch password requirements');
            }
        }
        init();
    }, []);

    // redirect
    React.useEffect(() => {
        if (isNullOrUndefined(redirectUrl)) {
            return;
        }
        props.PushHistory(redirectUrl);
    }, [redirectUrl, props]);

    async function handleSubmit(event) {
        event.preventDefault(); 
        setSubmitting(true);
        setWarningText(null);

        if (!acceptsTerms) {
            setWarningText('You must confirm you have read and agree to the Terms of Service and Privacy Policy');
            setSubmitting(false);
            return;
        }

        if (password !== passwordConfirmation) {
            setWarningText("Password and confirmation do not match");
            setSubmitting(false);
        }

        const response = await UserController.registerMagicLink(email, token, password, false);
        if (!response.hasError) {
            const { userName, role } = response.data;
            login(userName, role);
        } else {
            setWarningText(response.data);
            setSubmitting(false);
        }
    }

    function handleInput(event) {
        const { name, value, checked } = event.target;
        switch (name) {
            case "password":
                setPassword(value);
                break;
            case "passwordConfirmation":
                setPasswordConfirmation(value);
                break;
            case "acceptsTerms": 
                setAcceptsTerms(checked);
                break;
            default:
                return;
        }
    }

    function login(userName, role) {
        props.LogIn({
            userName,
            role,
            isLoggingIn: false,
            isAuthenticated: true,
        });
        setRedirectUrl("/");
    }

    async function checkPasswordIsValid() {
        setPasswordError(checkPassword(passwordRequirements, password));
    }

    function buildFormContent() {
        return <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        id="password-input"
                        label="Password"
                        type="password"
                        required
                        value={password}
                        onChange={handleInput}
                        name="password"
                        onBlur={() => checkPasswordIsValid()}
                        helperText={passwordError}
                        variant="filled"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="password-input"
                        label="Confirm Password"
                        type="password"
                        required
                        value={passwordConfirmation}
                        onChange={handleInput}
                        name="passwordConfirmation"
                        variant="filled"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} className={classes.checkboxWrapper}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={acceptsTerms}
                                onChange={handleInput}
                                name="acceptsTerms"
                                color="primary"
                            />
                        }
                        label={<span>
                            I have read and agree to the <a href="/EULA" target="_blank">Terms of Service</a> and <a href="/Privacy" target="_blank">Privacy Policy</a>
                        </span>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        type="submit"
                        disableElevation={true}
                        disabled={submitting}
                        variant="contained"
                        color="primary"
                    >
                        Register
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Alert header="Login failed!" text={warningText} />
                </Grid>
            </Grid>
        </form>;
    }

    function buildContent() {
        return <Container maxWidth="xs" className={classes.root}>
            <div className={classes.innerWrapper}>
            <img className={classes.fsLogo} src={logoImg} alt="Logo"/>
            <Typography variant="h4" className={classes.title}>
                Complete Sign Up
            </Typography>
            {buildFormContent()}
                </div>
        </Container>;
    }

    return buildContent();
}


const mapStateToProps = state => ({
    Auth: state.Authentication
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
    LogIn: data => dispatch(LogIn(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Register)

Register.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    LogIn: PropTypes.func,
    location: PropTypes.object,
};