import React from 'react';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Button, Container, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import { Alert } from '../../components/Common/Alert';

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: 32,
        marginBottom: 32,
    },
    title: {
        [theme.breakpoints.down("xs")]: {
            fontSize: 42,
        }
    }
}));

function PasswordChange(props) {
    const [currentPassword, setCurrentPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [warningText, setWarningText] = React.useState(null);
    const classes = useStyles();

    // initialise
    React.useState(() => {
        if (!props.Auth.isAuthenticated) {
            props.PushHistory('/Login');
        }
    }, [props]);

    // redirect
    React.useEffect(() => {
        if (isNullOrUndefined(redirectUrl)) {
            return;
        }
        props.PushHistory(redirectUrl);
    }, [redirectUrl, props]);

    async function handleSubmit(event) {
        event.preventDefault();
        setSubmitting(true);
        setWarningText(null);
        if (newPassword !== newPasswordConfirmation) {
            setWarningText('Your new passwords do not match');
            return;
        }
        const response = await UserController.changePassword(currentPassword, newPassword, newPasswordConfirmation);
        if (!response.hasError) {
            setRedirectUrl('/UserManagement');
        } else {
            setWarningText(response.data);
        }
        setSubmitting(false);
    }

    function handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;

        switch (name) {
            case "currentPassword":
                setCurrentPassword(value);
                break;
            case "newPassword":
                setNewPassword(value);
                break;
            case "newPasswordConfirmation":
                setNewPasswordConfirmation(value);
                break;
            default:
                return;
        }
    }

    return <Container maxWidth="xs" className={classes.wrapper}> 
        <Typography variant="h2" align="center" className={classes.title} gutterBottom>
            Change Password
        </Typography>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        id="current-password-input"
                        label="Current Password"
                        type="password"
                        value={currentPassword}
                        onChange={handleInput}
                        name="currentPassword"
                        variant="filled"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="new-password-input"
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={handleInput}
                        name="newPassword"
                        variant="filled"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="confirm-password-input"
                        label="Confirm New Password"
                        type="password"
                        value={newPasswordConfirmation}
                        onChange={handleInput}
                        name="newPasswordConfirmation"
                        variant="filled"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Alert header="Something went wrong!" text={warningText} />
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={submitting} color="primary" variant="contained" type="submit" fullWidth>
                        Change Password
                    </Button>
                </Grid>
            </Grid>
        </form>
    </Container>;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
})
const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordChange)

PasswordChange.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func
};