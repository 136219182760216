import React from "react";
import PropTypes from "prop-types";
import AdminController from "../../controllers/AdminController";
import ModuleController from "../../controllers/ModuleController";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Colours } from "../../helpers/Colours";
import { isNullOrUndefined, isNullOrWhitespace } from "../../helpers/Utils";
import { makeStyles, Typography, Grid, TextField, Button, Card, IconButton } from "@material-ui/core";
import { LoadingOverlay } from "../../components/Common/LoadingOverlay";
import { SpacedContainer } from "../../components/Common/SpacedContainer";
import { GreyCard } from "../../components/Common/GreyCard";
import { AdminAddEditUser } from "./AdminAddEditUser";
import { Pagination } from "@material-ui/lab";
import { Alert } from "../../components/Common/Alert";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(() => ({
	fullHeight: {
		height: "100%",
	},
	resultCount: {
		color: Colours.bodyText,
		margin: "8px 32px",
	},
	userCard: {
		position: "relative",
		height: 84,
		padding: "16px 16px 16px 21px",
		cursor: "pointer",
		"& .strip": {
			position: "absolute",
			left: 0,
			top: 0,
			height: "100%",
			width: 5,
		},
		"&:hover": {
			backgroundColor: Colours.bg_grey_1,
		},
	},
	paginator: {
		marginTop: 32,
		"& ul": {
			justifyContent: "center",
		},
	},
	search: {
		borderRadius: 4,
		height: "100%",
		width: "100%",
	},
}));

function Admin(props) {
	const { Auth, PushHistory } = props;
	const [email, setEmail] = React.useState("");
	const [users, setUsers] = React.useState([]);
	const [magicLinks, setMagicLinks] = React.useState([]);
	const [selectedMagicLink, setSelectedMagicLink] = React.useState(null);
	const [modules, setModules] = React.useState([]);
	const [page, setPage] = React.useState(1);
	const [adminHasSearched, setAdminHasSearched] = React.useState(false);
	const [showingMagicLinks, setShowingMagicLinks] = React.useState(false);
	const [lastPage, setLastPage] = React.useState(1);
	const [totalPages, setTotalPages] = React.useState(1);
	const [totalUsers, setTotalUsers] = React.useState(0);
	const [totalMagicLinks, setTotalMagicLinks] = React.useState(0);
	const [addingEditingUser, setAddingEditingUser] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [warningText, setWarningText] = React.useState(null);
	const [redirectUrl, setRedirectUrl] = React.useState(null);
	const classes = useStyles();

	const fetchUsers = React.useCallback(async (email = "", page = 1) => {
		setWarningText(null);
		setLoading(true);
		const response = await AdminController.getUsers(0, "Email", email);
		if (response.hasError) {
			setWarningText(response.data);
		} else {
			const { count, users } = response.data;
			setUsers(users);
			if (page === 1) {
				setTotalUsers(count);
			}
		}
		setLoading(false);
	}, []);

	const fetchMagicLinks = React.useCallback(async (email = "", page = 1) => {
		setWarningText(null);
		setLoading(true);
		const response = await AdminController.getMagicLinks(0, email);
		if (response.hasError) {
			setWarningText(response.data);
		} else {
			const { count, users } = response.data;
			setMagicLinks(users);
			if (page === 1) {
				setTotalMagicLinks(count);
			}
		}
		setLoading(false);
	}, []);

	const fetchAllModules = React.useCallback(async () => {
		setWarningText(null);
		setLoading(true);
		const response = await ModuleController.getAllModules();
		if (response.hasError) {
			setWarningText(response.data);
		} else {
			setModules(response.data);
		}
		setLoading(false);
	}, []);

	// initialise
	React.useEffect(() => {
		async function init() {
			if (!Auth.isAuthenticated) {
				setRedirectUrl("/Login");
				return;
			}
			await fetchUsers();
			await fetchAllModules();
			await fetchMagicLinks();
			calculateTotalPages();
		}
		init();
	}, [Auth, fetchUsers]);

	// redirect
	React.useEffect(() => {
		if (!isNullOrUndefined(redirectUrl)) {
			PushHistory(redirectUrl);
		}
	}, [PushHistory, redirectUrl]);

	// update page results on page change
	React.useEffect(() => {
		async function update() {
			await fetchUsers(email, page);
			await fetchMagicLinks(email, page);
		}
		if (lastPage !== page) {
			update();
			setLastPage(page);
		}
	}, [page, lastPage, email, fetchUsers]);

	React.useEffect(() => {
		calculateTotalPages();
	}, [showingMagicLinks]);

	function getUserStatus(user) {
		const { hasModualsAssigned, hasModualsInProgress, allModualsComplete, userIsBanned } = user;
		if (userIsBanned) {
			return { colour: Colours.red, status: "User Banned" };
		}
		if (allModualsComplete) {
			return { colour: Colours.secondary, status: "All Work Completed" };
		}
		if (hasModualsInProgress) {
			return { colour: Colours.primary, status: "Modules In-Progress" };
		}
		if (hasModualsAssigned) {
			return { colour: Colours.pink, status: "Modules Assigned" };
		}
		return { colour: Colours.bg_grey_2, status: "No Modules Assigned" };
	}

	function calculateTotalPages() {
		const count = showingMagicLinks ? totalMagicLinks : totalUsers;
		if (count > 100) {
			setTotalPages(Math.ceil(count / 100));
		}
	}

	function handleInput(event) {
		const { name, value } = event.target;
		switch (name) {
			case "email":
				setEmail(value);
				break;
			default:
				break;
		}
	}

	async function handleCloseAddEditUser(saved = false) {
		setAddingEditingUser(false);
		setSelectedMagicLink(null);
		if (saved) {
			setPage(1);
		}
	}

	function handleOnRemoveMagicLink(id) {
		const newArray = magicLinks.filter((el) => el.id !== id);
		setTotalMagicLinks(totalMagicLinks - 1);
		setMagicLinks(newArray);
	}

	function handleSearch() {
		setPage(1);
		fetchUsers(email, 1);
		fetchMagicLinks(email, 1);
		setAdminHasSearched(!isNullOrWhitespace(email) ? true : false);
	}

	function handleOpenAddUser() {
		setAddingEditingUser(true);
		setSelectedMagicLink(null);
	}

	function handleOpenEditUser(user) {
		setAddingEditingUser(true);
		setSelectedMagicLink(user);
	}

	function buildUserTile(user) {
		const { id, email, userIsBanned } = user;
		const { colour, status } = getUserStatus(user);
		return (
			<Grid item xs={12} md={6} key={id}>
				<Card elevation={3} className={classes.userCard} onClick={() => setRedirectUrl(`/AdminUserModules?id=${id}&email=${email}&userIsBanned=${userIsBanned}`)}>
					<div className="strip" style={{ backgroundColor: colour }}></div>
					<Typography variant="h5">{email}</Typography>
					<Typography variant="button" style={{ color: colour }}>
						{status}
					</Typography>
				</Card>
			</Grid>
		);
	}

	function buildMagicLinkTile(user) {
		const { id, email } = user;
		const { colour } = getUserStatus(user);
		return (
			<Grid item xs={12} md={6} key={id}>
				<Card elevation={3} className={classes.userCard} onClick={() => handleOpenEditUser(user)}>
					<div className="strip" style={{ backgroundColor: Colours.bg_grey_2 }}></div>
					<Typography variant="h5">{email}</Typography>
					<Typography variant="button" style={{ color: colour }}>
						Not Registered
					</Typography>
				</Card>
			</Grid>
		);
	}

	function buildPageSelector() {
		return <Pagination className={classes.paginator} count={totalPages} page={page} onChange={(_, val) => setPage(val)} />;
	}

	return (
		<SpacedContainer maxWidth="md">
			<LoadingOverlay loading={loading} />

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h2" gutterBottom>
						Dashboard
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="warning" text={warningText} />
				</Grid>
				<Grid item xs={12}>
					<GreyCard>
						<Typography variant="h5" paragraph>
							Manage Users
						</Typography>
						<Typography variant="body1" paragraph>
							Search for a specific user&apos;s email address, check their progress, assign new modules, and mark their work.
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={2} sm={1}>
								<IconButton className={classes.search} onClick={() => handleSearch()}>
									<SearchIcon />
								</IconButton>
							</Grid>
							<Grid item xs={10} sm={6} md={8}>
								<TextField
									label="Email Address"
									placeholder="example@flipside.uk.com"
									type="text"
									variant="filled"
									value={email}
									onChange={handleInput}
									onClick={() => handleSearch()}
									name="email"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={5} md={3}>
								<Button variant="contained" color="secondary" startIcon={<AddIcon />} className={classes.fullHeight} onClick={() => handleOpenAddUser()} fullWidth>
									Add New User
								</Button>
							</Grid>
						</Grid>
					</GreyCard>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="body1" className={classes.resultCount}>
						{showingMagicLinks ? totalMagicLinks : totalUsers} results
					</Typography>
				</Grid>
				<Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button variant="contained" color="primary" disableElevation={true} className={classes.fullHeight} onClick={() => setShowingMagicLinks(!showingMagicLinks)}>
						{showingMagicLinks ? "Show Registered Users" : "Show Unregistered Users"}
					</Button>
				</Grid>
				{showingMagicLinks ? magicLinks.map(buildMagicLinkTile) : users.map(buildUserTile)}
				<Grid item xs={12}>
					{buildPageSelector()}
				</Grid>
				<AdminAddEditUser
					modules={modules}
					isMagicLink={showingMagicLinks}
					open={addingEditingUser}
					onClose={handleCloseAddEditUser}
					magicLink={selectedMagicLink}
					onRemoveMagicLink={handleOnRemoveMagicLink}
				/>
			</Grid>
		</SpacedContainer>
	);
}

const mapStateToProps = (state) => ({
	Auth: state.Authentication,
});
const mapDispatchToProps = (dispatch) => ({
	PushHistory: (data) => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);

Admin.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
};
