import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ModuleController from '../../controllers/ModuleController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { isNullOrUndefined, isNullOrWhitespace } from '../../helpers/Utils';
import { makeStyles, Typography, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent, CardHeader, Divider, TextField, FormControlLabel, Checkbox, InputAdornment } from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { SpacedContainer } from '../../components/Common/SpacedContainer';
import { Alert } from '../../components/Common/Alert';
import { ContentType, QuestionType, QuestionRenderType, CompletionState, QuestionRenderFlags } from '../../helpers/Constants';

const useStyles = makeStyles(theme => ({
    contentWrapper: {
        marginBottom: 64,
    },
    email: {
        [theme.breakpoints.down("xs")]: {
            fontSize: 24,
        }
    },
    navButtons: {
        position: 'fixed',
        padding: "16px !important",
        top: 0,
        left: 56,
        maxWidth: 382,
        minWidth: 382,
        [theme.breakpoints.down("lg")]: {
            maxWidth: 'unset',
            minWidth: 'unset',
            left: 0,
            padding: "8px !important",
            position: 'relative',
        }
    },
    mainTitle: {
        [theme.breakpoints.down("xs")]: {
            fontSize: 32,
        }
    },  
    feedbackWrapper: {
        marginBottom: 32,
    }
}));

function AdminUserSubmoduleMarking(props) {
    const { id, module, submodule, email, parent, name, state } = queryString.parse(props.location.search);
    const { Auth, PushHistory } = props;
    const [moduleQuestions, setModuleQuestions] = React.useState([]);
    const [confirmDone, setConfirmDone] = React.useState(false);
    const [confirmReview, setConfirmReview] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [savingQuestion, setSavingQuestion] = React.useState(null);
    const [savingError, setSavingError] = React.useState(null);
    const [warningText, setWarningText] = React.useState(null);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const classes = useStyles();

    const isUnmarkable = state === CompletionState.NotStarted.toString() 
        || state === CompletionState.Complete.toString();

    const isRevertable = state !== CompletionState.NotStarted.toString()
        && state !== CompletionState.NeedsReview.toString()
        && state !== CompletionState.InProgress.toString();

    const fetchQuestionData = React.useCallback(async () => {
        const moduleResponse = await ModuleController.getUsersModuleItems(submodule, id);
        if (moduleResponse.hasError) {
            setWarningText(moduleResponse.data);
            return;
        } 
        const questionItems = moduleResponse.data.filter(e => e.type === ContentType.Question);
        const output = [];
        for (const item in questionItems) {
            const { questionId } = questionItems[item];
            const questionResponse = await ModuleController.getUsersModuleQuestion(module, questionId, id);
            if (questionResponse.hasError) {
                setWarningText(questionResponse.data);
            } else {
                output.push(questionResponse.data);
            }
        }
        setModuleQuestions(output);
    }, [module, submodule, id]);

    // initialise
    React.useEffect(() => {
        async function init() {
            setWarningText(null);
            setLoading(true);
            if (!Auth.isAuthenticated) {
                setRedirectUrl('/Login');
                return;
            }
            await fetchQuestionData();
            setLoading(false);
        }
        init();
    }, [Auth, fetchQuestionData]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [PushHistory, redirectUrl]);

    async function handleUpdateFeedback(questionId, mark, feedback) {
        const question = moduleQuestions.filter(e => e.id === questionId)[0];
        if (isNullOrUndefined(question)) {
            return;
        }
        question.feedback = { mark, feedback };
        setModuleQuestions(moduleQuestions.map(e => e.id === questionId ? question : e));
    }

    async function handleUnmarkDone() {
        setLoading(true);
        for (let i = 0; i < moduleQuestions.length; i++) {
            if (!isNullOrUndefined(moduleQuestions[i].feedback)) {
                await handleSendFeedback(moduleQuestions[i].id);
            }
        }
        const response = await ModuleController.setSubmoduleState(submodule, id, CompletionState.NeedsReview);
        if (response.hasError) {
            setSavingError(response.data?.data ?? "Please contact an administrator");
        } else {
            setRedirectUrl(`/AdminUserSections?id=${id}&module=${module}&name=${parent}&email=${email}`);
        }
        setLoading(false);
    }

    async function handleMarkComplete() {
        setLoading(true);
        for (let i = 0; i < moduleQuestions.length; i++) {
            if (!isNullOrUndefined(moduleQuestions[i].feedback)) {
                await handleSendFeedback(moduleQuestions[i].id);
            }
        }
        const response = await ModuleController.setSubmoduleState(submodule, id, CompletionState.Complete);
        if (response.hasError) {
            setSavingError(response.data?.data ?? "Please contact an administrator");
        } else {
            setRedirectUrl(`/AdminUserSections?id=${id}&module=${module}&name=${parent}&email=${email}`);
        }
        setLoading(false);
    }

    async function handleSendFeedback(questionId) {
        setLoading(true);
        setSavingQuestion(questionId);
        const question = moduleQuestions.filter(e => e.id === questionId)[0];
        if (isNullOrUndefined(question)) {
            setLoading(false);
            setSavingQuestion(null);
            return;
        }
        if (isNullOrUndefined(question.feedback)) {
            setSavingError("You must specify feedback before saving");
            setSavingQuestion(null);
            setLoading(false);
            return;
        }

        const { feedback, mark } = question.feedback;
        const response = await ModuleController.markQuestion(questionId, id, mark, feedback);
        if (response.hasError) {
            setSavingError(response.data?.data ?? "Please contact an administrator");
        }
        setLoading(false);
        setSavingQuestion(null);
    }

    function buildQuestionItem(question, index) {
        const { id, feedback, maximumMark } = question;
        return <Grid item xs={12} key={id} className={classes.feedbackWrapper}>
            <Card variant="outlined" square>
                <CardHeader title={"Question " + (index + 1)}/>
                <Divider />
                <CardContent>
                    {buildQuestionAnswer(question)}
                </CardContent>
                <Divider />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                value={feedback?.mark ?? ""}
                                variant="filled"
                                onChange={e => handleUpdateFeedback(id, e.target.value, feedback?.feedback)}
                                label={`Mark (0-${maximumMark})`}
                                disabled={isUnmarkable}
                                type="number"
                                step="1"
                                fullWidth
                            />
                        </Grid>
                        <Grid item  xs={12}>
                            <TextField
                                value={feedback?.feedback ?? ""}
                                onChange={e => handleUpdateFeedback(id, feedback?.mark, e.target.value)}
                                variant="filled"
                                label="Feedback"
                                disabled={isUnmarkable}
                                rows={3}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                disabled={savingQuestion === id || isUnmarkable} 
                                onClick={() => handleSendFeedback(id)}
                            >
                                Save Feedback
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>;
    }

    function buildQuestionAnswer(question) {
        const { type } = question;
        switch (type) {
            case QuestionType.Text:
            case QuestionType.MultipleText:
                return buildTextAnswer(question);
            case QuestionType.SingleChoice:
            case QuestionType.MultipleChoice:
                return buildChoiceAnswer(question);
            default: 
                return null;
        }
    } 

    function buildTextAnswer(question) {
        const { description, title, answers, type } = question;
        return <Grid container spacing={2}>
            {!isNullOrWhitespace(title)
                ? <Grid item xs={12}>
                    <Typography variant="h5">{title}</Typography>
                </Grid>
                : null}
            {!isNullOrWhitespace(description)
                ? <Grid item xs={12}>
                    <Typography variant="body1">{description}</Typography>
                </Grid>
                : null}
            {answers.map(e => {
                const { id: answerId, renderType, renderFlag, answerContent, content } = e;
                const isMultiline = renderType === QuestionRenderType.Multiline;
                const isCell = renderType === QuestionRenderType.Cell;
                const isDoubleCell = renderType === QuestionRenderType.DoubleColumnCell;
                const isCurrency = renderFlag === QuestionRenderFlags.Currency;
                return <Grid key={answerId} item md={isDoubleCell ? 6 : (isCell ? 4 : 12)} xs={(isCell || isDoubleCell) ? 6 : 12}>
                    <TextField
                        value={answerContent ?? "No Answer Given"}
                        variant="filled"
                        type="text"
                        rows={isMultiline ? 4 : 1}
                        InputProps={isCurrency ? {
                            startAdornment: <InputAdornment position="start">
                                £
                            </InputAdornment>
                        } : {}}
                        label={type === QuestionType.MultipleText ? content : `User's answer`}
                        fullWidth
                        multiline={isMultiline}
                        disabled={true}
                    />
                </Grid>;
            })}
        </Grid>;
    }

    function buildChoiceAnswer(question) {
        const { description, title, answers } = question;
        return <Grid container spacing={2}>
            {!isNullOrWhitespace(title)
                ? <Grid item xs={12}>
                    <Typography variant="h5">{title}</Typography>
                </Grid>
                : null}
            {!isNullOrWhitespace(description)
                ? <Grid item xs={12}>
                    <Typography variant="body1">{description}</Typography>
                </Grid>
                : null}
            {answers.map(e => {
                const { id: answerId, content, selected } = e;
                return <Grid key={answerId} item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selected}
                                disabled={true}
                                color="secondary"
                            />
                        }
                        label={content}
                    />
                </Grid>;
            })}
        </Grid>;
    }

    function buildErrorDialog() {
        return <Dialog open={!isNullOrUndefined(savingError)} onClose={() => setSavingError(null)}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>{savingError}</DialogContent>
            <DialogActions>
                <Button onClick={() => setSavingError(null)}>OK</Button>
            </DialogActions>
        </Dialog>;
    }

    return <SpacedContainer maxWidth="md">
        <LoadingOverlay loading={loading}/>

        <Grid container spacing={2} className={classes.contentWrapper}>
            <Grid item xs={12}>
                <Typography variant="h2" className={classes.mainTitle} gutterBottom> 
                    {name}
                </Typography>
                <Typography variant="h4" gutterBottom className={classes.email}> 
                    {email}
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.navButtons}>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={4} xs={12}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setRedirectUrl(`/AdminUserSections?id=${id}&module=${module}&name=${parent}&email=${email}`)}
                            fullWidth
                        >
                            Back to Sections
                        </Button>
                    </Grid>
                    <Grid item xl={12} lg={4} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setConfirmReview(true)}
                            fullWidth
                            disabled={!isRevertable}
                        >
                            Unmark as Done
                        </Button>
                    </Grid>
                    <Grid item xl={12} lg={4} xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setConfirmDone(true)}
                            fullWidth
                            disabled={isUnmarkable}
                        >
                            Mark as Complete
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Alert severity="warning" text={warningText}/>
            </Grid>

            {moduleQuestions.map((e, i) => buildQuestionItem(e, i))}

            <Grid item md={6} xs={12}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setConfirmDone(true)}
                    fullWidth
                    disabled={isUnmarkable}
                >
                    Mark as Complete
                </Button>
            </Grid>
            <Grid item md={6} xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setConfirmReview(true)}
                    fullWidth
                    disabled={!isRevertable}
                >
                    Unmark as Done
                </Button>
            </Grid>
        </Grid>

        <Dialog open={confirmDone} onClose={() => setConfirmDone(false)}>
            <DialogTitle>Mark as Complete</DialogTitle>
            <DialogContent>
                <Alert severity="info" header={"Are you sure?"} text={"This will notify the user that you have marked this module as complete."}/>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => handleMarkComplete()}>Mark as Complete</Button>
                <Button color="primary" onClick={() => setConfirmDone(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={confirmReview} onClose={() => setConfirmReview(false)}>
            <DialogTitle>Unmark as Done</DialogTitle>
            <DialogContent>
                <Alert severity="info" header={"Are you sure?"} text={"This will notify the user that you have marked this module as needing review."}/>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => handleUnmarkDone()}>Unmark as Done</Button>
                <Button color="primary" onClick={() => setConfirmReview(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>

        {buildErrorDialog()}
    </SpacedContainer>;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
})
const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminUserSubmoduleMarking)

AdminUserSubmoduleMarking.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    location: PropTypes.object,
};
