import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Container, Typography, Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Colours } from '../../helpers/Colours';
import { push } from 'connected-react-router';
import logoImg from '../../images/logo.svg';
import { Role } from '../../helpers/Constants';

const useStyles = makeStyles(theme => ({
    masthead: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: `100%`,
        padding: theme.spacing(2),
        minHeight: '100vh',
        backgroundColor: Colours.bg_grey_1,
        '& .logo-wrapper': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& img': {
                maxWidth: '100%',
                maxHeight: 400,
                borderRadius: 4,
                padding: 42,
            },
        }
    },
    mastheadInner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 60,
            paddingTop: 60,
        },
        '& p': {
            color: Colours.bodyText,
        },
    },
    btnMarginRight: {
        marginRight: 16,
    },
    btnMarginBottom: {
        marginBottom: 16,
    }
}));

function Home(props) {
    const { Auth } = props;
    const classes= useStyles();

    function buildButtons() {
        if (Auth.isAuthenticated) {
            return <>
                <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to={(Auth.role ?? []).includes(Role.Admin) ? "/Admin" : "/Course"}
                    className={`${classes.btnMarginRight} ${classes.btnMarginBottom}`}
                >
                    Go To Modules
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    component={Link}
                    to="/Guide"
                    className={classes.btnMarginBottom}
                >
                    How it Works
                </Button>
            </>;
        } else {
            return <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/Login"
            >
                Sign In / Register
            </Button>;
        }
    }

    return <div className={`${classes.masthead}`}>
        <Container maxWidth="md" className={classes.mastheadInner}>
            <Grid container spacing={4}>
                <Grid item md={5} sm={12} className="logo-wrapper">
                    <img src={logoImg} alt="Logo" />
                </Grid>
                <Grid item md={7} sm={12}>
                    <Typography variant="h2" gutterBottom>
                        Welcome
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We are delighted you have chosen the Flipside Money Management course. 
                        <br/>
                        <br/>
                        The course aims to give you the skills and confidence to manage your money effectively – it’s about <b>LIFE</b>, <b>MONEY</b> and <b>CHOICES</b>.
                        <br/>
                        <br/>
                        At Flipside, we have made the course interesting and meaningful – we hope you enjoy it.
                        <br/>
                        <br/>
                        Best wishes, 
                        <br/>
                        Flipside
                    </Typography>
                    {buildButtons()}
                </Grid>
            </Grid>
        </Container>
    </div>;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Home)

Home.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
};