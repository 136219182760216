import React from 'react';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LogOut } from '../../stores/Actions/Authentication';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

function CheckToken(props) { 
    const { Auth, LogOut, PushHistory } = props;
    const [showExpired, setShowExpired] = React.useState(false);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const location = useLocation();

    // check token when location updates
    React.useEffect(() => {
        async function checkToken() {
            if (Auth.isLoggingIn === true || Auth.isAuthenticated === false) {
                return;
            }
            const expired = await UserController.hasTokenExpired();
            if (expired) {
                LogOut();
                setRedirectUrl('/Login');
                setShowExpired(true);
                return;
            }
        }
        checkToken();
    }, [location, LogOut, Auth, setRedirectUrl, setShowExpired]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [redirectUrl, PushHistory]);

    return <Dialog open={showExpired} onClose={() => setShowExpired(false)}>
        <DialogTitle>Session Expired</DialogTitle>
        <DialogContent>
            Your previous session has expired, please login again
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setShowExpired(false)} color="primary">
                OK
            </Button>
        </DialogActions>
    </Dialog>;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
});

const mapDispatchToProps = dispatch => ({
    LogOut: () => dispatch(LogOut()),
    PushHistory: data => dispatch(push(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckToken);

CheckToken.propTypes = {
    Auth: PropTypes.object,
    LogOut: PropTypes.func,
    PushHistory: PropTypes.func,
};