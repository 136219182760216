import { Container, withStyles } from "@material-ui/core";

export const SpacedContainer = withStyles(theme => ({
    root: {
        marginTop: 80,
        paddingBottom: 80,
        [theme.breakpoints.down("xs")]: {
            marginTop: 30,    
        }
    }
}))(Container);