import TokenRefresher from "./TokenRefresher";

export function isNullOrUndefined(obj) {
    return obj === null || obj === undefined;
}

export function isNullOrEmpty(obj) {
    return obj === null || obj === undefined || obj === "";
}

export function isNullOrWhitespace(string) {
    return isNullOrUndefined(string) || string.trim().length === 0 
}

export function isStatusOK(status = 0) {
    return status >= 200 && status < 300;
}

export function replaceQueryParam(param, value, path = "") {
    const q = new URLSearchParams(window.location.search);
    q.set(param, value);
    window.history.replaceState(null, null, path + "?" + q.toString());
}

export function generateResponse(hasError = false, data, response = null) {
    if (!isNullOrUndefined(response)) {
        TokenRefresher.checkAndRefeshToken(response);
    } 
    return { hasError, data };
}

export function checkPassword(passwordRequirements, password) {
    if (isNullOrUndefined(passwordRequirements) || isNullOrUndefined(password)) {
        return;
    } 
    const validChars = Array.from("ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789!@$?_-");
    const currentChars = Array.from(password);
    currentChars.forEach(element => {
        if (!validChars.includes(element)) {
            return "Invalid character detected"; 
        }
    });
    if (currentChars.length < passwordRequirements.requiredLength) {
        return "Password must be at least " + passwordRequirements.requiredLength + " characters long";
    }
    if (passwordRequirements.requireDigit === true) {
        if (!/\d/.test(password)) {
            return "Password must contain one number";
        }
    }
    if (passwordRequirements.requireLowercase === true) {
        if (!/[a-z]/.test(password)) {
            return "Password must contain one lowercase character";
        }
    }
    if (passwordRequirements.requireUppercase === true) {
        if (!/[A-Z]/.test(password)) {
            return "Password must contain one uppercase character";
        }
    }
    if (passwordRequirements.requireNonAlphanumeric === true) {
        if (!/[!@$?_-]/.test(password)) {
            return "Password must contain one special character (!@$?_-)";
        }
    }
    return null;
}