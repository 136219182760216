import Axios from "axios";
import UserController from "./UserController";
import { APIRoute, APIPath } from "../helpers/Constants";
import { generateResponse } from "../helpers/Utils";

const CMSController = {
    addModule,
    editModule,
    deleteModule,
    addSubmodule,
    editSubmodule,
    deleteSubmodule,
    addModuleItem,
    editModuleItem,
    deleteModuleItem,
    addModuleQuestion,
    editModuleQuestion,
    deleteModuleQuestion,
};

async function addModule(name, orderIndex) {
    const userHeaders = await UserController.getUserHeaders();
    const data = { 
        name, 
        description: '', 
        orderIndex,
    };
    return Axios.post(APIRoute + APIPath.ADD_MODULE, data, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
} 

async function editModule(id, name, orderIndex) {
    const userHeaders = await UserController.getUserHeaders();
    const data = { 
        id,
        name, 
        description: '', 
        orderIndex,
    };
    return Axios.post(APIRoute + APIPath.EDIT_MODULE, data, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
} 

async function deleteModule(id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.DELETE_MODULE + id, null, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function addSubmodule(name, description, orderIndex, moduleId) {
    const userHeaders = await UserController.getUserHeaders();
    const data = { 
        name, 
        description, 
        orderIndex,
        moduleId,
    };
    return Axios.post(APIRoute + APIPath.ADD_SUBMODULE, data, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
} 

async function editSubmodule(id, name, description, orderIndex, moduleId) {
    const userHeaders = await UserController.getUserHeaders();
    const data = { 
        id,
        name, 
        description, 
        orderIndex,
        moduleId,
    };
    return Axios.post(APIRoute + APIPath.EDIT_SUBMODULE, data, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
} 

async function deleteSubmodule(id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.DELETE_SUBMODULE + id, null, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function addModuleItem(title, type, content, orderIndex, submoduleId, image, questionId) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('title', title);
    formData.append('type', type);
    formData.append('content', content);
    formData.append('orderIndex', orderIndex);
    formData.append('subModuleId', submoduleId);
    formData.append('image', image);
    formData.append('questionId', questionId);
    return Axios.post(APIRoute + APIPath.ADD_MODULE_ITEM, formData, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
} 

async function editModuleItem(id, title, type, content, orderIndex, submoduleId, image, questionId) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('id', id);
    formData.append('title', title);
    formData.append('type', type);
    formData.append('content', content);
    formData.append('orderIndex', orderIndex);
    formData.append('subModuleId', submoduleId);
    formData.append('image', image);
    formData.append('questionId', questionId);
    return Axios.post(APIRoute + APIPath.EDIT_MODULE_ITEM, formData, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
} 

async function deleteModuleItem(id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.DELETE_MODULE_ITEM + id, null, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function addModuleQuestion(title, description, type, orderIndex, maximumMark, moduleId, answers) {
    const userHeaders = await UserController.getUserHeaders();
    const data = { 
        title, 
        description, 
        type,
        orderIndex,
        maximumMark,
        moduleId,
        answers,
    };
    return Axios.post(APIRoute + APIPath.ADD_MODULE_QUESTION, data, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
} 

async function editModuleQuestion(id, type, title, description, orderIndex, maximumMark, answers) {
    const userHeaders = await UserController.getUserHeaders();
    const data = { 
        id,
        type,
        title, 
        description, 
        orderIndex,
        maximumMark,
        answers,
    };
    return Axios.post(APIRoute + APIPath.EDIT_MODULE_QUESTION, data, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
} 

async function deleteModuleQuestion(id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.DELETE_MODULE_QUESTION + id, null, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

export default CMSController;