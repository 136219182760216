import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Card } from '@material-ui/core';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 32,
        backgroundColor: Colours.bg_grey_1,
        [theme.breakpoints.down("xs")]: {
            padding: 16,
        },
        '& .MuiTypography-h5': {
            fontWeight: 500,
        },
        '& .MuiTypography-body1': {
            color: Colours.bodyText,
        }
    },
}));

export function GreyCard(props) {
    const { children, className } = props;
    const classes = useStyles();

    return <div className={className}>
        <Card className={classes.root} elevation={0} square>
            {children}
        </Card>
    </div>;
}

GreyCard.propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
};