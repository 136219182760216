import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ModuleController from '../../controllers/ModuleController';
import AdminController from '../../controllers/AdminController';
import CMSController from '../../controllers/CMSController';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Add, Delete, Remove, Save } from '@material-ui/icons';
import { makeStyles, Typography, Grid, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, Card, CardMedia, FilledInput, IconButton } from '@material-ui/core';
import { ContentType, QuestionType, QuestionRenderType, QuestionRenderFlags } from '../../helpers/Constants';
import { clearUploadUrls, getUploadUrls, readUploadUrls } from '../../helpers/UploadReader';
import { isNullOrUndefined, isNullOrWhitespace } from '../../helpers/Utils';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { SpacedContainer } from '../../components/Common/SpacedContainer';
import { GreyCard } from '../../components/Common/GreyCard';
import { Colours } from '../../helpers/Colours';
import { Alert } from '../../components/Common/Alert';

const useStyles = makeStyles(theme => ({
    contentWrapper: {
        marginBottom: 64,
    },
    navButtons: {
        position: 'fixed',
        padding: "16px !important",
        top: 0,
        left: 56,
        maxWidth: 382,
        minWidth: 382,
        [theme.breakpoints.down("lg")]: {
            maxWidth: 'unset',
            minWidth: 'unset',
            left: 0,
            padding: "8px !important",
            position: 'relative',
        }
    },
    mainTitle: {
        [theme.breakpoints.down("xs")]: {
            fontSize: 32,
        }
    },  
    image: {
        width: '100%',
        WebkitBoxShadow: '0px 0px 6px -1px rgba(0,0,0,0.48)',
        boxShadow: '0px 0px 6px -1px rgba(0,0,0,0.48)',
    },
    content: {
        fontSize: 22,
        color: Colours.bodyText,
        [theme.breakpoints.down("xs")]: {
            fontSize: 16,
        },
        '& pre' : {
            padding: 32,
            whiteSpace: 'normal',
            fontFamily: 'Roboto',
            backgroundColor: Colours.bg_grey_1,
            [theme.breakpoints.down("xs")]: {
                padding: 16,
            },
            '& p:first-child': {
                marginTop: 0,
            }
        }
    },
    question: {
        marginTop: 32,
        marginBottom: 32,
    },
    subtitle: {
        marginBottom: 64,
        marginTop: 64,
        [theme.breakpoints.down("xs")]: {
            fontSize: 26,
        },
    },
    editorWrapper: {
        backgroundColor: Colours.white,
        color: Colours.bodyText,
        '& .rdw-fontfamily-wrapper': {
            display: 'none',
        },
    },
    editorInner: {
        padding: 12,
        fontSize: 22,
        backgroundColor:'#fafafa',
        '& li':{
            '& .public-DraftStyleDefault-block':{
                margin: 0
            }
        }
    },
    imgBtn: {
        height: 260,
        cursor: 'pointer',
        backgroundColor: Colours.bg_grey_2,
        '& .MuiCardMedia-root': {
            height: '100%',
        },
        '& .MuiTypography-root': {
            marginTop: 120,
            pointerEvents: 'none',
            color: Colours.bodyText,
        },
        '&:hover': {
            opacity: 0.8,
        },
    }
}));

function CourseSubmodule(props) {
    const { module, submodule, name, parent } = queryString.parse(props.location.search);
    const { Auth, PushHistory } = props;
    const [moduleItems, setModuleItems] = React.useState([]);
    const [moduleQuestions, setModuleQuestions] = React.useState([]);
    const [editorStates, setEditorStates] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [deleteRef, setDeleteRef] = React.useState(null);
    const [addImageRef, setAddImageRef] = React.useState(null);
    const [image, setImage] = React.useState('');
    const [imageSrcs, setImageSrcs] = React.useState([]);
    const [savingError, setSavingError] = React.useState(null);
    const [warningText, setWarningText] = React.useState(null);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const classes = useStyles();

    const fetchModuleData = React.useCallback(async () => {
        const moduleResponse = await ModuleController.getModuleItems(submodule);
        if (moduleResponse.hasError) {
            setWarningText(moduleResponse.data);
            return [];
        } else {
            setModuleItems(moduleResponse.data.map(e => ({ ...e, needsSaving: false })));
            setEditorStates(moduleResponse.data.filter(e => e.type === ContentType.Text).map(e => {
                const contentBlock = htmlToDraft(e.content);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                return { id: e.id, state: editorState };
            }));
            return moduleResponse.data;
        }
    }, [submodule]);

    const fetchQuestionData = React.useCallback(async (data = []) => {
        const questionItems = data.filter(e => e.type === ContentType.Question);
        const output = [];
        for (const item in questionItems) {
            const { questionId } = questionItems[item];
            const questionResponse = await ModuleController.getModuleQuestion(module, questionId);
            if (questionResponse.hasError) {
                setWarningText(questionResponse.data);
            } else {
                output.push({ ...questionResponse.data, needsSaving: false });
            }
        }
        setModuleQuestions(output.sort((a, b) => a.orderIndex - b.orderIndex));
    }, [module]);

    // initialise
    React.useEffect(() => {
        async function init() {
            setWarningText(null);
            setLoading(true);
            if (!Auth.isAuthenticated) {
                setRedirectUrl('/Login');
                return;
            }
            const data = await fetchModuleData();
            await fetchQuestionData(data);
            setLoading(false);
        }
        init();
    }, [Auth, fetchModuleData, fetchQuestionData]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [PushHistory, redirectUrl]);

    function getNextItemOrderIndex() {
        if (moduleItems.length === 0) {
            return 0;
        } else {
            return moduleItems[moduleItems.length - 1].orderIndex + 1;
        }
    }

    function getEditorHtmlContent(itemId) {
        const state = editorStates.find(e => e.id === itemId).state;
        return draftToHtml(convertToRaw(state.getCurrentContent()));
    }

    async function handleRefreshMarks() {
        setWarningText(null);
        const response = await AdminController.refreshModuleMarks(module);
        if (response.hasError) {
            setSavingError(response.data);
        }
    }

    async function handleAddModuleQuestion() {
        setLoading(true);
        const response = await CMSController.addModuleQuestion(
            '',
            '',
            QuestionType.Text,
            0,
            4,
            module,
            [{
                content: '',
                orderIndex: 0,
                renderType: 1,
                renderFlag: 'text'
            }],
        );
        if (response.hasError) {
            setSavingError(response.data);
            setLoading(false);
            return null;
        } else {
            setModuleQuestions([...moduleQuestions, { ...response.data, needsSaving: false }]);
            setLoading(false);
            return response.data;
        }
    } 

    async function handleSaveModuleQuestion(questionId) {
        const question = moduleQuestions.find(e => e.id === questionId);
        const response = await CMSController.editModuleQuestion(
            questionId,
            question.type,
            question.title,
            question.description,
            question.orderIndex,
            question.maximumMark,
            question.answers,
        );
        if (response.hasError) {
            setSavingError(response.data);
        } else {
            setModuleQuestions(moduleQuestions.map(e => e.id === questionId ? { ...response.data, needsSaving: false } : e));
        }
    } 

    async function handleDeleteModuleQuestion(questionId) {
        setLoading(true);
        const response = await CMSController.deleteModuleQuestion(questionId);
        if (response.hasError) {
            setSavingError(response.data);
        } else {
            setModuleQuestions(moduleQuestions.filter(e => e.id !== questionId));
        }
        setLoading(false);
    }

    async function handleAddModuleItem() {
        setLoading(true);
        const response = await CMSController.addModuleItem(
            '',
            ContentType.Text,
            '',
            getNextItemOrderIndex(),
            submodule,
            null,
            null,
        );
        if (response.hasError) {
            setSavingError(response.data);
        } else {
            if (response.data.type === ContentType.Text) {
                const editorState = EditorState.createEmpty();
                setEditorStates([...editorStates, { id: response.data.id, state: editorState }]);
            }
            setModuleItems([...moduleItems, { ...response.data, needsSaving: false }]);
        }
        setLoading(false);
    }

    async function handleDeleteModuleItem(moduleItem) {
        setLoading(true);
        setDeleteRef(null);
        const { id, questionId } = moduleItem;
        const response = await CMSController.deleteModuleItem(id);
        if (response.hasError) {
            setSavingError(response.data);
        } else {
            setModuleItems(moduleItems.filter(e => e.id !== id));
            if (!isNullOrUndefined(questionId)) {
                await handleDeleteModuleQuestion(questionId);
                await handleRefreshMarks();
            }
        }
        setLoading(false);
    }

    async function handleSaveModuleItem(itemId, itemOverride = null) {
        setLoading(true);
        const moduleItem = !isNullOrUndefined(itemOverride) ? itemOverride : moduleItems.find(e => e.id === itemId);
        const { title, type, orderIndex, questionId } = moduleItem;
        const content = type === ContentType.Text
            ? getEditorHtmlContent(itemId)
            : moduleItem.content;
            
        const srcImg = imageSrcs.find(e => e.id === itemId);
        const image = !isNullOrWhitespace(content)
            ? content
            : !isNullOrUndefined(srcImg)
                ? srcImg.image
                : null;

        if (!isNullOrUndefined(questionId)) {
            const question = moduleQuestions.find(e => e.id === questionId);
            if (question?.needsSaving ?? false) {
                await handleSaveModuleQuestion(questionId);
            }
        }

        const response = await CMSController.editModuleItem(
            itemId,
            title, 
            type,
            content,
            orderIndex,
            submodule,
            image,
            questionId,
        );
        if (response.hasError) {
            setSavingError(response.data);
        } else {
            setModuleItems(moduleItems.map(e => e.id === itemId ? { ...response.data, needsSaving: false } : e));
            await handleRefreshMarks();
        }
        setLoading(false);
    }

    async function handleUpdateItemType(itemId, newType) {
        const item = moduleItems.find(e => e.id === itemId)
        let moduleItemsCopy = moduleItems;
        const oldType = item.type;
        if (oldType === newType) {
            return;
        }
        // init or remove wysiwyg editors as needed
        if (oldType === ContentType.Text) {
            setEditorStates(editorStates.filter(e => e.id !== itemId));
        } else if (newType === ContentType.Text) {
            const editorState = EditorState.createEmpty();
            setEditorStates([...editorStates, { id: itemId, state: editorState }]);
        }
        // remove images if needed
        if (oldType === ContentType.Image) {
            setImageSrcs(imageSrcs.filter(e => e.id !== itemId));
        }
        // handle question updating
        if (newType === ContentType.Question) {
            const question = await handleAddModuleQuestion(); 
            moduleItemsCopy = moduleItemsCopy.map(e => e.id === itemId ? { ...e, questionId: question.id } : e);
        } else if (oldType === ContentType.Question && !isNullOrUndefined(item.questionId)) {
            await handleDeleteModuleQuestion(item.questionId);
            moduleItemsCopy = moduleItemsCopy.map(e => e.id === itemId ? { ...e, questionId: null } : e);
        }
        // update type
        moduleItemsCopy = moduleItemsCopy.map(e => e.id === itemId ? { ...e, type: newType, content: '', needsSaving: newType !== ContentType.Question } : e);
        // update state
        setModuleItems(moduleItemsCopy);
        // save module item if question type
        if (newType === ContentType.Question) {
            await handleSaveModuleItem(itemId, moduleItemsCopy.find(e => e.id === itemId));
        }
    }

    function handleUpdateItemValue(itemId, identifier, newValue) {
        setModuleItems(moduleItems.map(e => e.id === itemId ? { ...e, [identifier]: newValue, needsSaving: true } : e).sort((a, b) => a.orderIndex - b.orderIndex));
    }

    function handleUpdateQuestionValue(itemId, questionId, identifier, newValue) {
        let questionsCopy = moduleQuestions;
        if (identifier === 'type') {
            const questionType = questionsCopy.find(e => e.id === questionId).type;
            if (questionType !== newValue) {
                questionsCopy = handleResetAnswers(questionsCopy, questionId);
            }
        }
        setModuleQuestions(questionsCopy.map(e => e.id === questionId ? { ...e, [identifier]: newValue, needsSaving: true } : e).sort((a, b) => a.orderIndex - b.orderIndex));
        setModuleItems(moduleItems.map(e => e.id === itemId ? { ...e, needsSaving: true } : e));
    }

    function handleUpdateQuestionAnswerValue(itemId, questionId, answerIndex, identifier, newValue) {
        setModuleQuestions(moduleQuestions.map(e => e.id === questionId 
            ? { ...e, answers: e.answers.map((a, i) => answerIndex === i ? { ...a, [identifier]: newValue } : a), needsSaving: true } 
            : e)
            .sort((a, b) => a.orderIndex - b.orderIndex));
        setModuleItems(moduleItems.map(e => e.id === itemId ? { ...e, needsSaving: true } : e));
    }

    function handleUpdateAllQuestionAnswerValues(itemId, questionId, identifier, newValue) {
        setModuleQuestions(moduleQuestions.map(e => e.id === questionId 
            ? { ...e, answers: e.answers.map(a => ({ ...a, [identifier]: newValue })), needsSaving: true } 
            : e)
            .sort((a, b) => a.orderIndex - b.orderIndex));
        setModuleItems(moduleItems.map(e => e.id === itemId ? { ...e, needsSaving: true } : e));
    }

    function handleResetAnswers(questionsArray, questionId) {
        return questionsArray.map(e => e.id === questionId 
            ? { ...e, needsSaving: true, answers: [{
                    content: '',
                    orderIndex: 0,
                    renderType: 1,
                    renderFlag: 'text',
                }] 
            }
            : e);
    }

    function handleAddAnswer(itemId, questionId) {
        const firstAnswer = moduleQuestions.find(e => e.id === questionId).answers[0];
        const { renderType } = firstAnswer;
        setModuleQuestions(moduleQuestions.map(e => e.id === questionId 
            ? { ...e, needsSaving: true, answers: [...e.answers, {
                    content: '',
                    orderIndex: e.answers.length,
                    renderType,
                    renderFlag: 'text',
                }] 
            }
            : e));
        setModuleItems(moduleItems.map(e => e.id === itemId ? { ...e, needsSaving: true } : e));
    }

    function handleRemoveAnswer(itemId, questionId) {
        const newAnswers = moduleQuestions.find(e => e.id === questionId).answers;
        newAnswers.pop();
        setModuleQuestions(moduleQuestions.map(e => e.id === questionId ? { ...e, needsSaving: true, answers: newAnswers } : e));
        setModuleItems(moduleItems.map(e => e.id === itemId ? { ...e, needsSaving: true } : e));
    }

    function handleUpdateEditor(itemId, editorState) {
        setEditorStates(editorStates.map(e => e.id === itemId ? { ...e, state: editorState } : e));
        setModuleItems(moduleItems.map(e => e.id === itemId ? { ...e, needsSaving: true } : e));
    }

    function handleAddImage(event) {
        const { files } = event.target;
        clearUploadUrls();
        if (!isNullOrUndefined(files[0])) {
            setImage(files[0]);
            readUploadUrls([files[0]]);
        }
    }

    function handleSaveImage(itemId) {
        if (getUploadUrls().length === 0) {
            setSavingError('Please upload an image before saving');
        }
        const newSrc = { id: itemId, image: image, local: getUploadUrls()[0] };
        if (imageSrcs.filter(e => e.id === itemId).length === 0) {
            setImageSrcs([...imageSrcs, newSrc]);
        } else {
            setImageSrcs(imageSrcs.map(e => e.id === itemId ? newSrc : e));
        }
        setModuleItems(moduleItems.map(e => e.id === itemId ? { ...e, needsSaving: true } : e));
        handleCloseImageModal();
    }

    function handleCloseImageModal() {
        setImage('');
        setAddImageRef(null);
    }

    function buildModuleItem(moduleItem) {
        const { id, title, type, orderIndex, needsSaving } = moduleItem;
        return <Grid item key={id} xs={12}>
            <GreyCard>
                <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                        <FormControl fullWidth variant="filled">
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={type}
                                onChange={(e) => handleUpdateItemType(id, e.target.value)}
                            >
                                <MenuItem value={ContentType.Text}>Text</MenuItem>
                                <MenuItem value={ContentType.Image}>Image</MenuItem>
                                <MenuItem value={ContentType.Question}>Question</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            variant="filled"
                            label="Order Index"
                            type="number"
                            step="1"
                            value={orderIndex}
                            onChange={e => handleUpdateItemValue(id, 'orderIndex', parseInt(e.target.value))}
                            name="orderIndex"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            variant="filled"
                            label="Title (optional)"
                            value={title}
                            onChange={(e) => handleUpdateItemValue(id, 'title', e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    {buildModuleItemForm(moduleItem)}
                    <Grid item sm={6}>
                        <Button 
                            disabled={!needsSaving} 
                            variant="contained" 
                            color="primary" 
                            onClick={() => handleSaveModuleItem(id)}
                            startIcon={<Save/>}
                            fullWidth
                        >
                            Save Changes
                        </Button>
                    </Grid>
                    <Grid item sm={6}>
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            onClick={() => setDeleteRef(moduleItem)}
                            startIcon={<Delete/>}
                            fullWidth
                        >
                            Delete Item
                        </Button>
                    </Grid>
                </Grid>
            </GreyCard>
        </Grid>;
    }

    function buildModuleItemForm(moduleItem) {
        const { type } = moduleItem;
        switch (type) {
            case ContentType.Text:
                return buildTextItemForm(moduleItem);
            case ContentType.Image:
                return buildImageItemForm(moduleItem);
            case ContentType.Question:
                return buildQuestionItemForm(moduleItem);
            default:
                return null;
        }
    }

    function buildTextItemForm(moduleItem) {
        const { id } = moduleItem;
        const editorState = editorStates.find(e => e.id === id)?.state;
        if (isNullOrUndefined(editorState)) {
            return null;
        }
        return <Grid item xs={12}>
            <Editor
                editorState={editorState}
                wrapperClassName={classes.editorWrapper}
                editorClassName={classes.editorInner}
                onEditorStateChange={(e) => handleUpdateEditor(id, e)}
            />
        </Grid>;
    }

    function buildImageItemForm(moduleItem) {
        const { content, id } = moduleItem;
        const srcImg = imageSrcs.find(e => e.id === id);
        const image = !isNullOrWhitespace(content)
            ? content
            : !isNullOrUndefined(srcImg)
                ? srcImg.local
                : null;
        return <Grid item xs={12}>
            <Card className={classes.imgBtn} onClick={() => setAddImageRef(moduleItem)}>
                {!isNullOrUndefined(image) 
                    ? <CardMedia image={image} />
                    : <Typography variant="body2" component="div" align="center">
                        Select an image
                    </Typography>}
            </Card>
        </Grid>;
    } 

    function buildQuestionItemForm(moduleItem) {
        if (moduleQuestions.length === 0) {
            return null;
        }
        const { id: itemId, questionId  } = moduleItem;
        const question = moduleQuestions.find(e => e.id === questionId);
        const { id, title, description, maximumMark } = question;
        return <>
            <Grid item xs={12}>
                <TextField
                    variant="filled"
                    label="Question Title (optional)"
                    value={title}
                    onChange={(e) => handleUpdateQuestionValue(itemId, id, 'title', e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="filled"
                    label="Question Description (optional)"
                    value={description}
                    onChange={(e) => handleUpdateQuestionValue(itemId, id, 'description', e.target.value)}
                    fullWidth
                />
            </Grid>
            {buildAnswerFormItems(itemId, question)}
            <Grid item sm={6}>
                <TextField
                    variant="filled"
                    label="Total Marks"
                    type="number"
                    step="1"
                    value={maximumMark}
                    onChange={e => handleUpdateQuestionValue(itemId, id, 'maximumMark', parseInt(e.target.value))}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item sm={6}></Grid>
        </>;
    }

    function buildAnswerFormItems(itemId, question) {
        const { type } = question; 
        switch (type) {
            case QuestionType.Text:
                return buildTextAnswerForm(itemId, question);
            case QuestionType.MultipleText:
                return buildMultipleTextAnswerForm(itemId, question);
            case QuestionType.MultipleChoice:
            case QuestionType.SingleChoice:
                return buildChoiceAnswerForm(itemId, question);
            default: 
                return null;
        } 
    }

    function buildQuestionTypeSelect(type, itemId, questionId) {
        return <Grid item sm={6} xs={12}>
            <FormControl fullWidth variant="filled">
                <InputLabel>Question Type</InputLabel>
                <Select
                    value={type}
                    onChange={(e) => handleUpdateQuestionValue(itemId, questionId, 'type', e.target.value)}
                >
                    <MenuItem value={QuestionType.Text}>Single Text</MenuItem>
                    <MenuItem value={QuestionType.MultipleText}>Multiple Text</MenuItem>
                    <MenuItem value={QuestionType.SingleChoice}>Single Choice</MenuItem>
                    <MenuItem value={QuestionType.MultipleChoice}>Multiple Choice</MenuItem>
                </Select>
            </FormControl>
        </Grid>;
    }

    function buildTextAnswerForm(itemId, question) {
        const { answers, type, id } = question;
        const answer = answers[0];
        const { renderType, renderFlag } = answer;
        return <>
            {buildQuestionTypeSelect(type, itemId, id)}
            <Grid item sm={6} xs={12}>
                <FormControl fullWidth variant="filled">
                    <InputLabel>Render Type</InputLabel>
                    <Select
                        value={renderType}
                        onChange={(e) => handleUpdateQuestionAnswerValue(itemId, id, 0, 'renderType', e.target.value)}
                    >
                        <MenuItem value={QuestionRenderType.Default}>Single Line</MenuItem>
                        <MenuItem value={QuestionRenderType.Multiline}>Multiline - Large</MenuItem>
                        <MenuItem value={QuestionRenderType.ExpandableMultiLine}>Multiline - Expanding</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth variant="filled">
                    <InputLabel>Input Type</InputLabel>
                    <Select
                        value={renderFlag ?? 'text'}
                        onChange={(e) => handleUpdateQuestionAnswerValue(itemId, id, 0, 'renderFlag', e.target.value)}
                    >
                        <MenuItem value={'text'}>Text</MenuItem>
                        <MenuItem value={QuestionRenderFlags.Currency}>Currency</MenuItem>
                        <MenuItem value={QuestionRenderFlags.Number}>Number</MenuItem>
                        <MenuItem value={QuestionRenderFlags.Percentage}>Percentage</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </>;
    }

    function buildMultipleTextAnswerForm(itemId, question) {
        const { answers, type, id } = question;
        const answer = answers[0];
        const { renderType } = answer;
        return <>
            {buildQuestionTypeSelect(type, itemId, id)}
            <Grid item sm={6} xs={12}>
                <FormControl fullWidth variant="filled">
                    <InputLabel>Render Type</InputLabel>
                    <Select
                        value={renderType}
                        onChange={(e) => handleUpdateAllQuestionAnswerValues(itemId, id, 'renderType', e.target.value)}
                    >
                        <MenuItem value={QuestionRenderType.Default}>Single Line</MenuItem>
                        <MenuItem value={QuestionRenderType.Multiline}>Multiline - Large</MenuItem>
                        <MenuItem value={QuestionRenderType.ExpandableMultiLine}>Multiline - Expanding</MenuItem>
                        <MenuItem value={QuestionRenderType.Cell}>Cell</MenuItem>
                        <MenuItem value={QuestionRenderType.DoubleColumnCell}>Double Column Cell</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {answers.sort((a, b) => a.orderIndex - b.orderIndex).map((e, i) => {
                const { renderFlag, content } = e;
                return <React.Fragment key={i}>
                    <Grid item xs={6}>
                        <TextField
                            variant="filled"
                            label="Input Name"
                            value={content}
                            onChange={(e) => handleUpdateQuestionAnswerValue(itemId, id, i, 'content', e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="filled">
                            <InputLabel>Input Type</InputLabel>
                            <Select
                                value={renderFlag ?? 'text'}
                                onChange={(e) => handleUpdateQuestionAnswerValue(itemId, id, i, 'renderFlag', e.target.value)}
                            >
                                <MenuItem value={'text'}>Text</MenuItem>
                                <MenuItem value={QuestionRenderFlags.Currency}>Currency</MenuItem>
                                <MenuItem value={QuestionRenderFlags.Number}>Number</MenuItem>
                                <MenuItem value={QuestionRenderFlags.Percentage}>Percentage</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </React.Fragment>;
            })}
            <Grid item xs={12}>
                <IconButton disabled={answers.length <= 1} onClick={() => handleRemoveAnswer(itemId, id)}>
                    <Remove/>
                </IconButton>
                <IconButton onClick={() => handleAddAnswer(itemId, id)}>
                    <Add/>
                </IconButton>
            </Grid>
        </>;
    } 

    function buildChoiceAnswerForm(itemId, question) {
        const { answers, type, id } = question;
        return <>
            {buildQuestionTypeSelect(type, itemId, id)}
            {answers.sort((a, b) => a.orderIndex - b.orderIndex).map((e, i) => {
                const { content } = e;
                return <Grid item key={i} xs={12}>
                    <TextField
                        variant="filled"
                        label={"Option " + (i + 1)}
                        value={content}
                        onChange={(e) => handleUpdateQuestionAnswerValue(itemId, id, i, 'content', e.target.value)}
                        fullWidth
                    />
                </Grid>;
            })}
            <Grid item xs={12}>
                <IconButton disabled={answers.length <= 1} onClick={() => handleRemoveAnswer(itemId, id)}>
                    <Remove/>
                </IconButton>
                <IconButton onClick={() => handleAddAnswer(itemId, id)}>
                    <Add/>
                </IconButton>
            </Grid>
        </>;
    }

    function buildUploadImageDialog() {
        return <Dialog open={!isNullOrUndefined(addImageRef)} onClose={() => handleCloseImageModal()}>
            <DialogTitle>Upload Image</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="filled">
                            <InputLabel shrink>Image</InputLabel>
                            <FilledInput
                                id="upload-input" 
                                aria-describedby="upload-helper" 
                                type="file" 
                                name="image" 
                                onChange={handleAddImage} 
                                inputProps={{
                                    accept: "image/*"
                                }}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={image === ''} onClick={() => handleSaveImage(addImageRef?.id)}>Confirm</Button>
                <Button color="primary" onClick={() => handleCloseImageModal()}>Close</Button>
            </DialogActions>
        </Dialog>;
    }

    function buildDeleteDialog() {
        return <Dialog open={!isNullOrUndefined(deleteRef)} onClose={() => setDeleteRef(null)}>
            <DialogTitle>Delete Module Item</DialogTitle>
            <DialogContent>
                <Typography variant="body2">
                    Are you sure you want to delete {isNullOrWhitespace(deleteRef?.title) ? 'the selected module item' : deleteRef.title}? <br/><br/>
                    <b>This action cannot be reversed.</b>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => handleDeleteModuleItem(deleteRef)}>Confirm</Button>
                <Button color="primary" onClick={() => setDeleteRef(null)}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }

    function buildErrorDialog() {
        return <Dialog open={!isNullOrUndefined(savingError)} onClose={() => setSavingError(null)}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
                <Alert severity="error" text={savingError}/>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => setSavingError(null)}>OK</Button>
            </DialogActions>
        </Dialog>;
    }

    return <SpacedContainer maxWidth="md">
        <LoadingOverlay loading={loading}/>

        <Grid container spacing={2} className={classes.contentWrapper}>
            <Grid item xs={12}>
                <Typography variant="h2" className={classes.mainTitle} gutterBottom> 
                    {name}
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.navButtons}>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={5} xs={12}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setRedirectUrl(`/CMSSections?module=${module}&name=${parent}`)}
                            fullWidth
                        >
                            Back to Sections
                        </Button>
                    </Grid>
                    <Grid item xl={12} lg={5} xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setRedirectUrl(`/CMSSectionPreview?module=${module}&submodule=${submodule}&name=${name}&parent=${parent}`)}
                            fullWidth
                        >
                            Preview Section
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Alert severity="warning" text={warningText}/>
            </Grid>

            {moduleItems.map(buildModuleItem)}

            <Grid item md={6} xs={12}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleAddModuleItem()}
                    startIcon={<Add/>}
                    fullWidth
                >
                    Add Item
                </Button>
            </Grid>
        </Grid>

        {buildUploadImageDialog()}
        {buildDeleteDialog()}
        {buildErrorDialog()}
    </SpacedContainer>;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
})
const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CourseSubmodule)

CourseSubmodule.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    location: PropTypes.object,
};
