import { isNullOrUndefined } from "./Utils";

var readers = [];

export function clearUploadUrls() {
    readers = [];
}

export function getUploadUrls() {
    return readers.map(e => { return e.result });
}

export function removeIndex(i) {
    readers.splice(i,1);
}

export function readUploadUrls(files, callback) {
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (isNullOrUndefined(file)) {
            continue;
        }
        var reader = new FileReader();
        readers.push(reader);
        reader.onload = () => {
            if (!isNullOrUndefined(callback)) {
                callback();
            }
        }
        reader.readAsDataURL(file);
    }
}