import React from 'react';
import PropTypes from 'prop-types';
import ModuleController from '../../controllers/ModuleController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Colours } from '../../helpers/Colours';
import { isNullOrUndefined } from '../../helpers/Utils';
import { makeStyles, Typography, Grid, Card, Avatar } from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { SpacedContainer } from '../../components/Common/SpacedContainer';
import { Alert } from '../../components/Common/Alert';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import EditIcon from '@material-ui/icons/Edit';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const useStyles = makeStyles(() => ({
    moduleCard: {
        position: 'relative',
        height: 110,
        padding: '16px 62px 16px 21px',
        cursor: 'pointer',
        '& h5': {
            marginTop: 20,
        },
        '& .strip': {
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: 5,
        },
        '& .marks': {
            position: 'absolute',
            color: Colours.bodyText,
            top: 16,
            left: 21,
        },
        '& .MuiAvatar-root': {
            position: 'absolute',
            right: 16,
            top: 16,
        },
        '&:hover': {
            backgroundColor: Colours.bg_grey_1,
        }
    },
    modulesWrapper: {
        marginBottom: 64,
    },
    noModules: {
        color: Colours.bodyText,
    }
}));

function Course(props) {
    const { Auth, PushHistory } = props;
    const [modules, setModules] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [warningText, setWarningText] = React.useState(null);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const classes = useStyles();

    const fetchModuleData = React.useCallback(async () => {
        setWarningText(null);
        setLoading(true);
        const moduleResponse = await ModuleController.getMyModules();
        if (moduleResponse.hasError) {
            setWarningText(moduleResponse.data);
        } else {
            setModules(moduleResponse.data);
        }
        setLoading(false);
    }, []);

    // initialise
    React.useEffect(() => {
        async function init() {
            if (!Auth.isAuthenticated) {
                setRedirectUrl('/Login');
                return;
            }
            await fetchModuleData();
        }
        init();
    }, [Auth, fetchModuleData]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [PushHistory, redirectUrl]);

    function getModuleStatus(module) {
        const { isComplete, isStarted } = module;
        if (isComplete) {
            return { colour: Colours.secondary, status: "Completed", icon: <DoneAllIcon/> };
        }
        if (isStarted) {
            return { colour: Colours.primary, status: "Started", icon: <EditIcon/> };
        }
        return { colour: Colours.pink, status: "Assigned", icon: <AssignmentLateIcon/> };
    }

    function buildModuleTile(module) {
        const { name, id: moduleId, maximumMark, currentMark } = module;
        const { colour, status, icon } = getModuleStatus(module);
        return <Grid item xs={12}  key={moduleId}>
            <Card elevation={3} className={classes.moduleCard} onClick={() => setRedirectUrl(`/CourseModule?&module=${moduleId}&name=${name}`)}>
                <div className="strip" style={{ backgroundColor: colour }}></div>
                <Typography variant="caption" className="marks">
                    {currentMark}/{maximumMark}{maximumMark === 1 ? '' : ' total'} possible mark{maximumMark === 1 ? '' : 's'}
                </Typography>
                <Typography variant="h5" gutterBottom>
                    {name}
                </Typography>
                <Typography variant="button" style={{ color: colour }}>
                    {status}
                </Typography>
                <Avatar style={{ backgroundColor: colour }}>
                    {icon}
                </Avatar>
            </Card>
        </Grid>
    }

    return <SpacedContainer maxWidth="md">
        <LoadingOverlay loading={loading}/>

        <Grid container spacing={2} className={classes.modulesWrapper}>
            <Grid item xs={12}>
                <Typography variant="h2" gutterBottom> 
                    My Modules
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Alert severity="warning" text={warningText}/>
            </Grid>

            {modules.map(buildModuleTile)}

            {modules.length === 0 
                ? <Grid item xs={12}>
                    <Typography variant="h5" className={classes.noModules}>
                        {loading ? null : "Thank you for registering. The administrator will assign modules to you and you will be notified."}
                    </Typography>
                </Grid>
                : null}
        </Grid>
    </SpacedContainer>;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
})
const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Course)

Course.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    location: PropTypes.object,
};
