import React from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colours.bg_grey_2,
    }
}));

export default function CourseImageViewer(props) {
    const { imgUrl } = queryString.parse(props.location.search);
    const classes = useStyles();

    return <div className={classes.root}>
        <img src={imgUrl} alt="Focus"/>
    </div>;
}

CourseImageViewer.propTypes = {
    location: PropTypes.object,
};
