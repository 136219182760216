const NOTIFS_INITIAL_STATE = [];

const Notifications = (state = NOTIFS_INITIAL_STATE, action) => {
    switch (action.type) {
        case 'ADD_NOTIF':
            return [action.item, ...state];
        case 'ADD_NOTIFS':
            return [...action.items, ...state];
        case 'MARK_AS_READ':
            return state.map(e => e.id === action.id ? { markedAsRead: true, ...e } : e);
        case 'MARK_ALL_AS_READ':
            return state.map(e => { return { markedAsRead: true, ...e } });
        case 'CLEAR_ALL_NOTIFS':
            return NOTIFS_INITIAL_STATE;
        default:
            return state;
    }
};

export default Notifications;