export const versionName = "WebApp";

export const versionNum = 16;

export const APIDomain = "https://flipsidecoursemodules-api.azurewebsites.net";

export const APIRoute = APIDomain + "/api/";

export const APIPath = {
    // Admin
    ADMIN_GET_USERS: "Admin/GetUsers",
    ADMIN_GET_MAGIC_LINKS: "Admin/GetMagicLinks",

    // Users
    PASSWORD_REQUIREMENTS: "User/PasswordRequirements",
    LOGIN: "User/LogIn",
    LOGIN_PASSWORD_RESET: "User/LogInWithPasswordReset",
    LOGIN_GOOGLE: "User/LoginWithGoogle",
    LOGOUT: "User/LogOut",
    REGISTER: "User/Register",
    REGISTER_GOOGLE: "User/RegisterWithGoogle",
    EMAIL_CONFIRMATION: "User/EmailConfirmation",
    FORGOT_PASSWORD: "User/ForgotPassword",
    RESET_PASSWORD: "User/ResetPassword",
    CHANGE_PASSWORD: "User/ChangePassword",
    GET_USER_INFO: "User/GetUsersInfo",
    REQUEST_EMAIL_CHANGE: "User/RequestChangeEmail",
    CONFIRM_EMAIL_CHANGE: "User/ConfirmChangeEmail",
    REQUEST_EMAIL_CONFIRMATION: "User/RequestEmailConfirmation",
    FINISH_EXTERNAL_LOGIN: "User/CheckLocalUser",
    FINISH_EXTERNAL_SIGNUP: "User/CreateLocalUser",
    TWO_FACTOR_GET_KEY: "User/GetAuthenticatorCode",
    TWO_FACTOR_ADD: "User/AddAuthentication",
    TWO_FACTOR_REMOVE: "User/RemoveAuthentication",
    ADD_DEVICE: "User/AddDevice",
    UPDATE_USER_ALTERNATE_LOGINS: "User/UpdateUsersAlternateLogins",
    VERIFY_TWO_FACTOR: "User/VerifyTwoFactor",
    GET_MOTD: "User/GetMOTD",
    SEEN_MOTD: "User/SeenMOTD",
    REFRESH_TOKEN: "User/RefreshToken",
    CREATE_MAGIC_LINKS: "User/CreateMagicLinks",
    UPDATE_MAGIC_LINK: "User/UpdateMagicLink",
    REMOVE_MAGIC_LINK: "User/RemoveMagicLink/",
    RESEND_MAGIC_LINK: "User/ReSendMagicLink/",
    REGISTER_MAGIC_LINK: "User/RegisterMagicLink",
    BAN_USER: "User/BanUser/",
    UN_BAN_USER: "User/UnBanUser/",

    // Modules
    GET_MY_MODULES: "Module/MyModules",
    GET_MY_SUBMODULES: "Module/SubModules/",
    GET_MY_MODULE_ITEMS: "Module/ModuleItems/",
    GET_MY_MODULE_QUESTION: "Module/ModuleQuestion/",
    SUBMIT_ANSWERS: "Module/SubmitAnswers",
    GET_ALL_MODULES: "Module/AllModules",
    GET_SUBMODULES: "Module/SubModules/",
    GET_MODULE_ITEMS: "Module/ModuleItems/",
    GET_MODULE_QUESTION: "Module/ModuleQuestion/",
    GET_USERS_MODULES: "Module/UsersModules/",
    GET_USERS_SUBMODULES: "Module/UsersSubModules/",
    GET_USER_MODULE_ITEMS: "Module/UsersModuleItems/",
    GET_USER_MODULE_QUESTION: "Module/UsersModuleQuestion/",
    ADD_USER_TO_MODULE: "Module/AddUserToModule",
    REMOVE_USER_FROM_MODULE: "Module/RemoveUserFromModule",
    COMPLETE_SUBMODULE: "Module/CompleteSubModule/",
    SET_SUBMODULE_STATE: "Module/SetSubModuleState",
    MARK_QUESTION: "Module/MarkQuestion/",
    REFRESH_MARKS: "Module/RefreshModuleMaxMark/",

    // CMS
    ADD_MODULE: "Module/AddModule",
    EDIT_MODULE: "Module/EditModule",
    DELETE_MODULE: "Module/DeleteModule/",
    ADD_SUBMODULE: "Module/AddSubModule",
    EDIT_SUBMODULE: "Module/EditSubModule",
    DELETE_SUBMODULE: "Module/DeleteSubModule/",
    ADD_MODULE_ITEM: "Module/AddModuleItem",
    EDIT_MODULE_ITEM: "Module/EditModuleItem",
    DELETE_MODULE_ITEM: "Module/DeleteModuleItem/",
    ADD_MODULE_QUESTION: "Module/AddModuleQuestion",
    EDIT_MODULE_QUESTION: "Module/EditModuleQuestion",
    DELETE_MODULE_QUESTION: "Module/DeleteModuleQuestion/",

    // Notifications
    GET_UNREAD_NOTIF_COUNT: "Notifications/GetUnreadCount",
    GET_TOP_NOTIFS: "Notifications/GetTop",
    GET_ALL_NOTIFS: "Notifications/GetAll",
    MARK_NOTIF_AS_READ: "Notifications/MarkAsRead/",
    MARK_ALL_NOTIFS_AS_READ: "Notifications/MarkAllAsRead",

    // File
    FILE_UPlOAD: "File/Upload",
    FILE_GET_USERS: "File/GetUsersFiles",

    // Version
    GET_VERSION: "Version",
};

export const SIGNALRPath = {
    Notifications: APIDomain + "/webhooks/notification",
};

export const Role = {
    Admin: "Admin",
};

export const CompletionState = {
    NotStarted: 0,
    InProgress: 1,
    AwaitingMarking: 2,
    NeedsReview: 4,
    Complete: 8,
};

export const ContentType = {
    Text: 1,
    Image: 2,
    Question: 4,
};

export const QuestionType = {
    SingleChoice: 1,
    MultipleChoice: 2,
    Text: 4,
    MultipleText: 8,
};

export const QuestionRenderType = {
    Default: 1,
    Multiline: 2,
    Cell: 4,
    DoubleColumnCell: 8,
};

export const QuestionRenderFlags = {
    Currency: "currency",
    Number: "number",
    Percentage: "percentage",
};
