import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Colours } from '../../helpers/Colours';
import { isNullOrUndefined } from '../../helpers/Utils';
import { makeStyles, Typography, Grid, Button, Card, Avatar, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { CompletionState } from '../../helpers/Constants';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { SpacedContainer } from '../../components/Common/SpacedContainer';
import { Alert } from '../../components/Common/Alert';
import ClearIcon from '@material-ui/icons/Clear';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ModuleController from '../../controllers/ModuleController';

const useStyles = makeStyles(theme => ({
    email: {
        [theme.breakpoints.down("xs")]: {
            fontSize: 24,
        }
    },
    moduleCard: {
        position: 'relative',
        height: 110,
        padding: '16px 62px 16px 21px',
        cursor: 'pointer',
        '& h5': {
            marginTop: 20,
            [theme.breakpoints.down("xs")]: {
                fontSize: 14,
                marginBottom: 20,
            }
        },
        '& span': {
            [theme.breakpoints.down("xs")]: {
                position: 'absolute',
                bottom: 8,
            }
        },
        '& .strip': {
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: 5,
        },
        '& .marks': {
            position: 'absolute',
            color: Colours.bodyText,
            top: 16,
            left: 21,
        },
        '& .MuiAvatar-root': {
            position: 'absolute',
            right: 16,
            top: 16,
        },
        '&:hover': {
            backgroundColor: Colours.bg_grey_1,
        }
    },
    navButtons: {
        position: 'fixed',
        padding: "16px !important",
        top: 0,
        left: 56,
        maxWidth: 382,
        minWidth: 382,
        [theme.breakpoints.down("lg")]: {
            maxWidth: 'unset',
            minWidth: 'unset',
            left: 0,
            padding: "8px !important",
            position: 'relative',
        }
    },
    modulesWrapper: {
        marginBottom: 64,
    }
}));

function AdminUserSubmodules(props) {
    const { id, module, name, email } = queryString.parse(props.location.search);
    const { Auth, PushHistory } = props;
    const [userSubmodules, setUserSubmodules] = React.useState([]);
    const [revokeModule, setRevokeModule] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [warningText, setWarningText] = React.useState(null);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const classes = useStyles();

    const fetchModuleData = React.useCallback(async () => {
        setWarningText(null);
        setLoading(true);
        const moduleResponse = await ModuleController.getUsersSubmodules(module, id);
        if (moduleResponse.hasError) {
            setWarningText(moduleResponse.data);
        } else {
            setUserSubmodules(moduleResponse.data);
        }
        setLoading(false);
    }, [id, module]);

    // initialise
    React.useEffect(() => {
        async function init() {
            if (!Auth.isAuthenticated) {
                setRedirectUrl('/Login');
                return;
            }
            await fetchModuleData();
        }
        init();
    }, [Auth, fetchModuleData]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [PushHistory, redirectUrl]);

    function getSubmoduleStatus(submodule) {
        const { state } = submodule;
        switch (state) {
            case CompletionState.InProgress:
                return { colour: Colours.primary, status: "Started", icon: <EditIcon/> };
            case CompletionState.AwaitingMarking: 
                return { colour: Colours.olive, status: "Completed and Unmarked", icon: <DoneIcon/> }; 
            case CompletionState.NeedsReview: 
                return { colour: Colours.pink, status: "Feedback Given", icon: <AssignmentIcon/> }; 
            case CompletionState.Complete:
                return { colour: Colours.secondary, status: "Completed and Marked", icon: <DoneAllIcon/> };
            default: 
                return { colour: Colours.bg_grey_2, status: "Not Started", icon: <ClearIcon/> }
        }
    }

    async function handleRevokeAccess() {
        setLoading(true);
        setWarningText(null);
        const response = await ModuleController.removeUserFromModule(id, module);
        if (response.hasError) {
            setWarningText(response.data);
            setLoading(false);
        } else {
            setRedirectUrl(`/AdminUserModules?id=${id}&email=${email}`);
        }
    }

    function buildSubmoduleTile(submodule) {
        const { name: submoduleName, id: submoduleId, state, maximumMark, currentMark } = submodule;
        const userModule = userSubmodules.filter(e => e.id === submoduleId)[0];
        const { colour, status, icon } = getSubmoduleStatus(userModule);
        return <Grid item xs={12} key={submodule.id}>
            <Card 
                elevation={3} 
                className={classes.moduleCard} 
                onClick={() => setRedirectUrl(`/AdminUserSubmoduleMarking?id=${id}&module=${module}&submodule=${submoduleId}&email=${email}&name=${submoduleName}&parent=${name}&state=${state}`)}
            >
                <div className="strip" style={{ backgroundColor: colour }}></div>
                <Typography variant="caption" className="marks">
                    {currentMark}/{maximumMark}{maximumMark === 1 ? '' : ' total'} possible mark{maximumMark === 1 ? '' : 's'}
                </Typography>
                <Typography variant="h5" gutterBottom>
                    {submoduleName}
                </Typography>
                <Typography variant="button" style={{ color: colour }}>
                    {status}
                </Typography>
                <Avatar style={{ backgroundColor: colour }}>
                    {icon}
                </Avatar>
            </Card>
        </Grid>
    }

    function buildRevokeModuleDialog() {
        return <Dialog open={revokeModule} onClick={() => setRevokeModule(false)}>
            <DialogTitle>Revoke Module Access?</DialogTitle>
            <DialogContent>
                Are you sure you want to revoke access to {name} for {email}? <br/><br/>
                <b>This user will not be able to access this module any more.</b>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={loading} onClick={() => handleRevokeAccess()}>
                    Revoke Access
                </Button>
                <Button color="primary" onClick={() => setRevokeModule(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    return <SpacedContainer maxWidth="md">
        <LoadingOverlay loading={loading}/>

        <Grid container spacing={2} className={classes.modulesWrapper}>
            <Grid item xs={12}>
                <Typography variant="h2" gutterBottom> 
                    {name}
                </Typography>
                <Typography variant="h4" gutterBottom className={classes.email}> 
                    {email}
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.navButtons}>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={4} xs={12}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setRedirectUrl(`/AdminUserModules?id=${id}&email=${email}`)}
                            fullWidth
                        >
                            Back to Modules
                        </Button>
                    </Grid>
                    <Grid item xl={12} lg={4} xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setRevokeModule(true)}
                            fullWidth
                        >
                            Revoke Module Access
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Alert severity="warning" text={warningText}/>
            </Grid>

            {userSubmodules.map(buildSubmoduleTile)}

            {buildRevokeModuleDialog()}
        </Grid>
    </SpacedContainer>;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
})
const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminUserSubmodules)

AdminUserSubmodules.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    location: PropTypes.object,
};
