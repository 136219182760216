import Axios from "axios";
import UserController from "./UserController";
import { APIRoute, APIPath } from "../helpers/Constants";
import { generateResponse } from "../helpers/Utils";

const NotificationsController = {
    getUnreadCount,
    getTop,
    getAll,
    markAsRead,
    markAllAsRead,
}

async function getUnreadCount() {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_UNREAD_NOTIF_COUNT, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getTop() {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_TOP_NOTIFS, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getAll(page = 0) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_ALL_NOTIFS + `?page=${page}`, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function markAsRead(id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.MARK_NOTIF_AS_READ + id, null, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function markAllAsRead() {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.MARK_ALL_NOTIFS_AS_READ, null, userHeaders).then(response => {
        return generateResponse(false, response.data, response);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

export default NotificationsController;