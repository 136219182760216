import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ModuleController from '../../controllers/ModuleController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Colours } from '../../helpers/Colours';
import { isNullOrUndefined } from '../../helpers/Utils';
import { makeStyles, Typography, Grid, Card, Button, IconButton, Dialog, DialogTitle, DialogActions, DialogContent, TextField } from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { SpacedContainer } from '../../components/Common/SpacedContainer';
import { Alert } from '../../components/Common/Alert';
import { Add, Delete, Edit } from '@material-ui/icons';
import CMSController from '../../controllers/CMSController';

const useStyles = makeStyles(theme => ({
    moduleCard: {
        position: 'relative',
        height: 110,
        padding: '16px 62px 16px 21px',
        '& h5': {
            marginTop: 20,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            [theme.breakpoints.down("xs")]: {
                fontSize: 14,
                marginBottom: 20,
            }
        },
        '& .strip': {
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: 5,
        },
        '& .marks': {
            position: 'absolute',
            color: Colours.bodyText,
            top: 16,
            left: 21,
        },
        '& .time': {
            position: 'absolute',
            color: Colours.bodyText,
            bottom: 12,
            right: 21,
            [theme.breakpoints.down("xs")]: {
                bottom: 8,
            }
        },
        '& .tools': {
            position: 'absolute',
            display: 'flex',
            top: 6,
            right: 6,
            '& .MuiIconButton-root': {
                marginLeft: 2,
            }
        },
    },
    navButtons: {
        position: 'fixed',
        padding: "16px !important",
        top: 0,
        left: 56,
        maxWidth: 382,
        minWidth: 382,
        [theme.breakpoints.down("lg")]: {
            maxWidth: 'unset',
            minWidth: 'unset',
            left: 0,
            padding: "8px !important",
            position: 'relative',
        }
    },
    modulesWrapper: {
        marginBottom: 64,
    },
    noModules: {
        color: Colours.bodyText,
    }
}));

function CMSSubmodules(props) {
    const { module, name } = queryString.parse(props.location.search);
    const { Auth, PushHistory } = props;
    const [submodules, setSubmodules] = React.useState([]);
    const [editRef, setEditRef] = React.useState(null);
    const [deleteRef, setDeleteRef] = React.useState(null);
    const [addShowing, setAddShowing] = React.useState(false);
    const [submoduleName, setSubmoduleName] = React.useState('');
    const [duration, setDuration] = React.useState('');
    const [orderIndex, setOrderIndex] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [warningText, setWarningText] = React.useState(null);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const classes = useStyles();

    const fetchModuleData = React.useCallback(async () => {
        setWarningText(null);
        setLoading(true);
        const moduleResponse = await ModuleController.getSubmodules(module);
        if (moduleResponse.hasError) {
            setWarningText(moduleResponse.data);
        } else {
            setSubmodules(moduleResponse.data);
        }
        setLoading(false);
    }, [module]);

    // initialise
    React.useEffect(() => {
        async function init() {
            if (!Auth.isAuthenticated) {
                setRedirectUrl('/Login');
                return;
            }
            await fetchModuleData();
        }
        init();
    }, [Auth, fetchModuleData]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [PushHistory, redirectUrl]);

    // init edit form
    React.useEffect(() => {
        if (editRef === null) {
            setSubmoduleName('');
            setOrderIndex('');
            setDuration('');
        } else {
            setSubmoduleName(editRef?.name ?? '');
            setOrderIndex(editRef?.orderIndex ?? '');
            setDuration(editRef?.description ?? '');
        }
    }, [editRef]) 

    async function handleAddEditSubmodule(event) {
        event.preventDefault();
        const tempEditRef = editRef;
        const editing = !isNullOrUndefined(tempEditRef) 
        setLoading(true);
        setAddShowing(false);
        setEditRef(null);
        const response = editing
            ? await CMSController.editSubmodule(tempEditRef.id, submoduleName, duration, parseInt(orderIndex), module)
            : await CMSController.addSubmodule(submoduleName, duration, parseInt(orderIndex), module);
        if (response.hasError) {
            setWarningText(response.data);
        } else {
            editing
                ? setSubmodules(submodules.map(e => e.id === tempEditRef.id ? response.data : e).sort((a, b) => a.orderIndex - b.orderIndex))
                : setSubmodules([...submodules, response.data].sort((a, b) => a.orderIndex - b.orderIndex));
        }
        setLoading(false);
    }

    async function handleDeleteSubmodule() {
        const tempDeleteRef = deleteRef;
        setLoading(true);
        setDeleteRef(null);
        const response = await CMSController.deleteSubmodule(tempDeleteRef.id);
        if (response.hasError) {
            setWarningText(response.data);
        } else {
            setSubmodules(submodules.filter(e => e.id !== tempDeleteRef.id));
        }
        setLoading(false);
    }

    function handleInput(event) {
        const { name, value } = event.target;
        switch (name) {
            case "name":
                setSubmoduleName(value);
                break;
            case "duration":
                setDuration(value);
                break;
            case "orderIndex":
                setOrderIndex(value);
                break;
            default:
                return;
        }
    }

    function handleCloseAddEdit() {
        setAddShowing(false);
        setEditRef(null);
    }

    function buildSubmoduleTile(submodule) {
        const { name: submoduleName, id: submoduleId, maximumMark, description, orderIndex } = submodule;
        return <Grid item xs={12} key={submoduleId}>
            <Card 
                elevation={3} 
                className={classes.moduleCard} 
            >
                <div className="strip" style={{ backgroundColor: Colours.secondary }}></div>
                <Typography variant="caption" className="marks">
                    {maximumMark}{maximumMark === 1 ? '' : ' total'} possible mark{maximumMark === 1 ? '' : 's'}
                </Typography>
                <Typography variant="caption" className="time">
                    approx. {description} long
                </Typography>
                <Typography variant="h5" gutterBottom>
                    {submoduleName}
                </Typography>
                <Typography variant="caption">
                    <b>Order Index: #{orderIndex}</b>
                </Typography>
                <div className="tools">
                    <IconButton onClick={() => setEditRef(submodule)} size="small">
                        <Edit/>
                    </IconButton>
                    <IconButton onClick={() => setDeleteRef(submodule)} size="small">
                        <Delete/>
                    </IconButton>
                </div>
            </Card>
        </Grid>
    }

    function buildAddEditSubmoduleDialog() {
        const editing = !isNullOrUndefined(editRef);
        return <Dialog open={addShowing || editing} onClose={() => handleCloseAddEdit()}>
            <form onSubmit={handleAddEditSubmodule}> 
                <DialogTitle>{editing ? 'Edit' : 'Add New'} Section</DialogTitle>
                {editing ?
                    <DialogContent>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setRedirectUrl(`/CMSSection?&module=${module}&submodule=${editRef.id}&name=${submoduleName}&parent=${name}`)}
                            startIcon={<Edit/>}
                        >
                            Edit Section Items
                        </Button>
                    </DialogContent>
                    : null}
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="filled"
                                label="Name"
                                value={submoduleName}
                                onChange={handleInput}
                                name="name"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="filled"
                                label="Estimated Length"
                                value={duration}
                                onChange={handleInput}
                                helperText="e.g. '1 hr' or '30 mins'"
                                name="duration"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="filled"
                                label="Order Index"
                                type="number"
                                step="1"
                                value={orderIndex}
                                onChange={handleInput}
                                name="orderIndex"
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={loading} type="submit">
                        {editing ? 'Save' : 'Add'} Section
                    </Button>
                    <Button color="primary" onClick={() => handleCloseAddEdit()}>
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>;
    }

    function buildDeleteSubmoduleDialog() {
        return <Dialog open={!isNullOrUndefined(deleteRef)} onClose={() => setDeleteRef(null)}>
            <DialogTitle>Confirm Delete Section</DialogTitle>
            <DialogContent>
                <Typography variant="body1" paragraph>
                    Are your sure you want to delete {deleteRef?.name ?? ''}? <br/><br/>
                    <b>This action cannot be reversed.</b>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={loading} onClick={() => handleDeleteSubmodule()}>
                    Confirm
                </Button>
                <Button color="primary" onClick={() => setDeleteRef(null)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    return <SpacedContainer maxWidth="md">
        <LoadingOverlay loading={loading}/>

        <Grid container spacing={2} className={classes.modulesWrapper}>
            <Grid item xs={12}>
                <Typography variant="h2" gutterBottom> 
                    {name}
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.navButtons}>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={4} sm={5} xs={12}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setRedirectUrl('/CMSModules')}
                            fullWidth
                        >
                            Back to Modules
                        </Button>
                    </Grid>
                    <Grid item xl={12} lg={4} sm={5} xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setAddShowing(true)}
                            startIcon={<Add/>}
                            fullWidth
                        >
                            Add New Section
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Alert severity="warning" text={warningText}/>
            </Grid>

            {submodules.map(buildSubmoduleTile)}
        </Grid>

        {buildAddEditSubmoduleDialog()}
        {buildDeleteSubmoduleDialog()}
    </SpacedContainer>;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
})
const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CMSSubmodules)

CMSSubmodules.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    location: PropTypes.object,
};
