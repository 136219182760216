import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ModuleController from '../../controllers/ModuleController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { isNullOrUndefined, isNullOrWhitespace } from '../../helpers/Utils';
import { makeStyles, Typography, Grid, TextField, Button, FormControlLabel, Checkbox, InputAdornment } from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { SpacedContainer } from '../../components/Common/SpacedContainer';
import { Alert } from '../../components/Common/Alert';
import { ContentType, QuestionType, QuestionRenderType, QuestionRenderFlags } from '../../helpers/Constants';
import { Colours } from '../../helpers/Colours';
import { GreyCard } from '../../components/Common/GreyCard';

const useStyles = makeStyles(theme => ({
    contentWrapper: {
        marginBottom: 64,
    },
    navButtons: {
        position: 'fixed',
        padding: "16px !important",
        top: 0,
        left: 56,
        maxWidth: 382,
        minWidth: 382,
        [theme.breakpoints.down("lg")]: {
            maxWidth: 'unset',
            minWidth: 'unset',
            left: 0,
            padding: "8px !important",
            position: 'relative',
        }
    },
    mainTitle: {
        [theme.breakpoints.down("xs")]: {
            fontSize: 32,
        }
    },  
    image: {
        width: '100%',
        WebkitBoxShadow: '0px 0px 6px -1px rgba(0,0,0,0.48)',
        boxShadow: '0px 0px 6px -1px rgba(0,0,0,0.48)',
    },
    content: {
        fontSize: 22,
        color: Colours.bodyText,
        [theme.breakpoints.down("xs")]: {
            fontSize: 16,
        },
        '& pre' : {
            padding: 32,
            whiteSpace: 'normal',
            fontFamily: 'Roboto',
            backgroundColor: Colours.bg_grey_1,
            [theme.breakpoints.down("xs")]: {
                padding: 16,
            },
            '& p:first-child': {
                marginTop: 0,
            }
        }
    },
    question: {
        marginTop: 32,
        marginBottom: 32,
    },
    subtitle: {
        marginBottom: 64,
        marginTop: 64,
        [theme.breakpoints.down("xs")]: {
            fontSize: 26,
        },
    },
}));

function CMSSubmodulePreview(props) {
    const { module, submodule, name, parent } = queryString.parse(props.location.search);
    const { Auth, PushHistory } = props;
    const [moduleItems, setModuleItems] = React.useState([]);
    const [moduleQuestions, setModuleQuestions] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [warningText, setWarningText] = React.useState(null);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const classes = useStyles();

    const fetchModuleData = React.useCallback(async () => {
        const moduleResponse = await ModuleController.getModuleItems(submodule);
        if (moduleResponse.hasError) {
            setWarningText(moduleResponse.data);
            return [];
        } else {
            setModuleItems(moduleResponse.data);
            return moduleResponse.data;
        }
    }, [submodule]);

    const fetchQuestionData = React.useCallback(async (data = []) => {
        const questionItems = data.filter(e => e.type === ContentType.Question);
        const output = [];
        for (const item in questionItems) {
            const { questionId } = questionItems[item];
            const questionResponse = await ModuleController.getModuleQuestion(module, questionId);
            if (questionResponse.hasError) {
                setWarningText(questionResponse.data);
            } else {
                output.push(questionResponse.data);
            }
        }
        setModuleQuestions(output);
    }, [module]);

    // initialise
    React.useEffect(() => {
        async function init() {
            setWarningText(null);
            setLoading(true);
            if (!Auth.isAuthenticated) {
                setRedirectUrl('/Login');
                return;
            }
            const data = await fetchModuleData();
            await fetchQuestionData(data);
            setLoading(false);
        }
        init();
    }, [Auth, fetchModuleData, fetchQuestionData]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [PushHistory, redirectUrl]);

    function buildModuleItem(moduleItem) {
        const { id, title } = moduleItem;
        return <Grid item key={id} xs={12}>
            {!isNullOrWhitespace(title) 
                ? <Typography variant="h4" className={classes.subtitle}>{title}</Typography>
                : null}
            {buildModuleItemContent(moduleItem)}
        </Grid>;
    }

    function buildModuleItemContent(moduleItem) {
        const { title, type, content } = moduleItem;
        switch (type) {
            case ContentType.Image:
                return <img className={classes.image} src={content + "?" + moment().get('time')} alt={title} />;
            case ContentType.Text:
                return <div className={classes.content} dangerouslySetInnerHTML={{ __html: content }}></div>
            case ContentType.Question:
                return buildModuleItemQuestion(moduleItem);
            default:
                return <Typography>INVALID MODULE ITEM DETECTED</Typography>;
        }
    }

    function buildModuleItemQuestion(moduleItem) {
        const question = moduleQuestions.filter(e => e.id === moduleItem.questionId)[0];
        if (isNullOrUndefined(question)) {
            return <Typography>INVALID QUESTION DETECTED</Typography>
        }
        
        const getQuestion = () => {
            const { type } = question;
            switch (type) {
                case QuestionType.Text:
                case QuestionType.MultipleText:
                    return buildTextQuestion(question);
                case QuestionType.SingleChoice:
                case QuestionType.MultipleChoice:
                    return buildChoiceQuestion(question);
                default: 
                    return null;
            }
        }

        return getQuestion();
    }

    function buildTextQuestion(question) {
        const { description, title, answers, type, maximumMark } = question;
        return <GreyCard className={classes.question}>
            <Grid container spacing={1}>
                {!isNullOrWhitespace(title)
                    ? <Grid item xs={12}>
                        <Typography variant="h5">{title}</Typography>
                    </Grid>
                    : null}
                {!isNullOrWhitespace(description)
                    ? <Grid item xs={12}>
                        <Typography variant="body1">{description}</Typography>
                    </Grid>
                    : null}
                {answers.map((e, i) => {
                    const { id: answerId, renderType, renderFlag, answerContent, content } = e;
                    const isMultiline = renderType === QuestionRenderType.Multiline ||renderType === QuestionRenderType.ExpandableMultiLine;
                    const isLargeMultiLine = renderType === QuestionRenderType.Multiline;
                    const isCell = renderType === QuestionRenderType.Cell;
                    const isDoubleCell = renderType === QuestionRenderType.DoubleColumnCell;
                    const isNumber = (renderFlag === QuestionRenderFlags.Number || renderFlag === QuestionRenderFlags.Currency|| renderFlag === QuestionRenderFlags.Percentage);
                    const isCurrency = renderFlag === QuestionRenderFlags.Currency;
                    const isPercentage = renderFlag === QuestionRenderFlags.Percentage;
                    const isLast = i === answers.length - 1;
                    return <React.Fragment key={answerId}>
                        {isDoubleCell && isLast && i % 2 === 0
                            ? <Grid item xs={6}></Grid>
                            : null}
                        <Grid item md={isDoubleCell ? 6 : (isCell ? 4 : 12)} xs={(isCell || isDoubleCell) ? 6 : 12}>
                            <TextField
                                value={answerContent ?? ""}
                                variant="filled"
                                type={isNumber ? "number" : "text"}
                                rows={isMultiline ? isLargeMultiLine ? 50: 4 : 1}
                                InputProps={isCurrency || isPercentage ? {
                                    startAdornment: isCurrency? <InputAdornment position="start">
                                    {isCurrency ? '£' : '' }
                                </InputAdornment> : null,
                                    endAdornment:isPercentage? <InputAdornment position="start">
                                    {isPercentage? '%' : ''} 
                                </InputAdornment>: null
                                } : {}}
                                label={type === QuestionType.MultipleText ? content : "Your answer"}
                                fullWidth={!isNumber || isMultiline}
                                multiline={isMultiline}
                                disabled
                            />
                        </Grid>
                    </React.Fragment>;
                })}
                <Grid item xs={12}>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        disabled
                    >
                        Save Answer
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption">
                        {maximumMark}{maximumMark === 1 ? '' : ' total'} possible mark{maximumMark === 1 ? '' : 's'}
                    </Typography>
                </Grid>
           </Grid>
        </GreyCard>
    }

    function buildChoiceQuestion(question) {
        const { description, title, answers, maximumMark } = question;
        return <GreyCard className={classes.question}>
            <Grid container spacing={2}>
                {!isNullOrWhitespace(title)
                    ? <Grid item xs={12}>
                        <Typography variant="h5">{title}</Typography>
                    </Grid>
                    : null}
                {!isNullOrWhitespace(description)
                    ? <Grid item xs={12}>
                        <Typography variant="body1">{description}</Typography>
                    </Grid>
                    : null}
                {answers.map(e => {
                    const { id: answerId, content } = e;
                    return <Grid key={answerId} item xs={12}>
                        <FormControlLabel
                            disabled
                            control={
                                <Checkbox
                                    checked={false}
                                    color="secondary"
                                    disabled
                                />
                            }
                            label={content}
                        />
                    </Grid>;
                })}
                <Grid item xs={12}>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        disabled
                    >
                        Save Answer
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption">
                        {maximumMark}{maximumMark === 1 ? '' : ' total'} possible mark{maximumMark === 1 ? '' : 's'}
                    </Typography>
                </Grid>
           </Grid>
        </GreyCard>
    }

    return <SpacedContainer maxWidth="md">
        <LoadingOverlay loading={loading}/>

        <Grid container spacing={2} className={classes.contentWrapper}>
            <Grid item xs={12}>
                <Typography variant="h2" className={classes.mainTitle} gutterBottom> 
                    {name}
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.navButtons}>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={5} xs={12}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setRedirectUrl(`/CMSSection?module=${module}&submodule=${submodule}&name=${name}&parent=${parent}`)}
                            fullWidth
                        >
                            Back to Editor
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Alert severity="warning" text={warningText}/>
            </Grid>

            {!loading 
                ? moduleItems.map(buildModuleItem)
                : null}
        </Grid>
    </SpacedContainer>;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
})
const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CMSSubmodulePreview)

CMSSubmodulePreview.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    location: PropTypes.object,
};
