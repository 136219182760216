import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import { connect } from 'react-redux';
import { QRCode } from "react-qr-svg";
import { push } from 'connected-react-router';
import { Button, Container, Grid, Typography, Card, CardContent, CardHeader, Divider, TextField } from '@material-ui/core';
import { Alert } from '../../components/Common/Alert';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';

function TwoFactorManager(props) {
    const [warningText, setWarningText] = React.useState(null);
    const [hasTwoFactorAuth, setHasTwoFactorAuth] = React.useState(false);
    const [twoFactorAuthSetup, setTwoFactorAuthSetup] = React.useState(null);
    const [twoFactorAuthToken, setTwoFactorAuthToken] = React.useState('');
    const [twoFactorAuthRecovery, setTwoFactorAuthRecovery] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const fetchUserData = useCallback(async () => {
        setWarningText(null);
        const response = await UserController.getUserInfo();
        if (response.hasError) {
            setWarningText(response.data);
            return;
        }
        const { hasTwoFactor } = response.data;
        if (!hasTwoFactor) {
            const authCode = await UserController.getAuthenticatorCode();
            if (authCode.hasError) {
                setWarningText(authCode.data);
                return;
            }
            setTwoFactorAuthSetup(authCode.data);
        }
        setHasTwoFactorAuth(hasTwoFactor);
    }, []);

    // intialise
    React.useEffect(() => {
        async function init() {
            if (!props.Auth.isAuthenticated) {
                props.PushHistory('/Login');
            }
            await fetchUserData();
            setLoading(false);
        }
        init();
    }, [props, fetchUserData]);

    async function handleAdd(event) {
        event.preventDefault();
        setWarningText(null);
        setLoading(true);
        const response = await UserController.addTwoFactor(twoFactorAuthSetup.secret64, twoFactorAuthToken);
        if (response.hasError) {
            setWarningText(response.data)
        } else {
            setTwoFactorAuthRecovery(response.data);
            setTwoFactorAuthToken('');
            setHasTwoFactorAuth(true);
        }
        setLoading(false);
    }

    async function handleRemove(event) {
        event.preventDefault();
        setLoading(true);
        setWarningText(null);
        const response = await UserController.removeTwoFactor(twoFactorAuthToken);
        if (response.hasError) {
            setWarningText(response.data)
        } else {
            setTwoFactorAuthRecovery(null);
            setTwoFactorAuthToken('');
            setHasTwoFactorAuth(false);
            await fetchUserData();
        }
        setLoading(false);
    }

    function handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;

        switch (name) {
            case "twoFactorAuthToken":
                setTwoFactorAuthToken(value);
                break;
            default:
                return;
        }
    }

    function buildAuthQRCode() {
        let authQrCode = null;
        if (!hasTwoFactorAuth && twoFactorAuthSetup != null) {
            authQrCode = <>
                <Grid item xs={12} style={{ textAlign: 'center' }}> 
                    <QRCode
                        bgColor="#FFFFFF"
                        fgColor="#000000"
                        level="Q"
                        style={{ width: 200 }}
                        value={twoFactorAuthSetup.qrCode}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardHeader subheader={<Typography variant="subtitle2" align="center">
                            If the above doesn&apos;t work, try the code
                        </Typography>} />
                        <Divider/>
                        <CardContent style={{ padding: 10 }}>
                            <Typography align="center" style={{ lineBreak: 'anywhere' }}>
                                {twoFactorAuthSetup.secret}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </>;
        }
        return authQrCode;
    }

    function buildAddForm() {
        return <>
            {buildAuthQRCode()}
            <Grid item xs={12}>
                <TextField
                    id="twoFactorAuthToken-input"
                    label="Two Factor Auth Code"
                    type="text"
                    value={twoFactorAuthToken}
                    onChange={handleInput}
                    name="twoFactorAuthToken"
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth variant="contained" type="submit">
                    Add Authenticator
                </Button>
            </Grid>
        </>;
    }

    function buildRecoveryCode() {
        return twoFactorAuthRecovery != null 
            ? <Grid item xs={12}>
                <Card variant="outlined">
                    <CardHeader subheader={<Typography variant="subtitle2" align="center">
                        Your Recovery Code is
                    </Typography>} />
                    <Divider/>
                    <CardContent style={{ padding: 10 }}>
                        <Typography align="center" style={{ lineBreak: 'anywhere' }}>
                            {twoFactorAuthRecovery}
                        </Typography>
                    </CardContent>
                    <Divider/>
                    <CardContent style={{ padding: 10 }}>
                        <Typography variant="subtitle2" align="center">
                            This can be used in place of your authenticatior code. Please keep it safe.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            : null;
    }

    function buildRemoveForm() {
        return <>
            <Grid item xs={12}>
                <TextField
                    id="twoFactorAuthToken-input"
                    label="Two Factor Auth Code"
                    type="text"
                    value={twoFactorAuthToken}
                    onChange={handleInput}
                    name="twoFactorAuthToken"
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth variant="contained" type="submit">
                    Remove Authenticator
                </Button>
            </Grid>
        </>;
    }

    return <Container maxWidth="xs">
        <LoadingOverlay loading={loading}/>

        <form onSubmit={hasTwoFactorAuth ? handleRemove : handleAdd}>
            <Typography variant="h3" align="center" gutterBottom>
                Two Factor Login
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Alert header="Something went wrong!" text={warningText} />
                </Grid>
                {buildRecoveryCode()}
                {hasTwoFactorAuth ? buildRemoveForm() : buildAddForm()}
            </Grid>
        </form>
    </Container>;

}

const mapStateToProps = state => ({
    Auth: state.Authentication
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TwoFactorManager)

TwoFactorManager.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func
};