import Axios from "axios";
import UserController from "./UserController";
import { APIPath, APIRoute } from "../helpers/Constants";
import { generateResponse } from "../helpers/Utils";

const ModuleController = {
    getMyModules,
    getMySubmodules,
    getMyModuleItems,
    getMyModuleQuestion,
    submitAnswers,
    getAllModules,
    getSubmodules,
    getModuleItems,
    getModuleQuestion,
    getUsersModules,
    getUsersSubmodules,
    getUsersModuleItems,
    getUsersModuleQuestion,
    addUserToModule,
    removeUserFromModule,
    completeSubmodule,
    setSubmoduleState,
    markQuestion,
};

async function getMyModules() {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_MY_MODULES, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getMySubmodules(moduleId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_MY_SUBMODULES + moduleId, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getMyModuleItems(submoduleId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_MY_MODULE_ITEMS + submoduleId, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getMyModuleQuestion(moduleId, questionId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_MY_MODULE_QUESTION + moduleId + "/" + questionId, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function submitAnswers(moduleQuestionId, answers) {
    const userHeaders = await UserController.getUserHeaders();
    const answersModel = {
        moduleQuestionId,
        answers,
    };
    return Axios.post(APIRoute + APIPath.SUBMIT_ANSWERS, answersModel, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getAllModules() {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_ALL_MODULES, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getSubmodules(moduleId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_SUBMODULES + moduleId, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getModuleItems(submoduleId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_MODULE_ITEMS + submoduleId, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getModuleQuestion(moduleId, questionId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_MODULE_QUESTION + moduleId + '/' + questionId, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getUsersModules(userId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_USERS_MODULES + userId, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getUsersSubmodules(moduleId, userId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_USERS_SUBMODULES + moduleId + "/" + userId, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getUsersModuleItems(subModuleId, userId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_USER_MODULE_ITEMS + subModuleId + "/" + userId, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function getUsersModuleQuestion(moduleId, questionId, userId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_USER_MODULE_QUESTION + moduleId + "/" + questionId  + "/" + userId, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function addUserToModule(userId, moduleId) {
    const userHeaders = await UserController.getUserHeaders();
    const addModel = { userId, moduleId };
    return Axios.post(APIRoute + APIPath.ADD_USER_TO_MODULE, addModel, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}
async function removeUserFromModule(userId, moduleId) {
    const userHeaders = await UserController.getUserHeaders();
    const removeModel = { userId, moduleId };
    return Axios.post(APIRoute + APIPath.REMOVE_USER_FROM_MODULE, removeModel, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function completeSubmodule(id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.COMPLETE_SUBMODULE + id, null, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function setSubmoduleState(subModuleId, userId, state) {
    const userHeaders = await UserController.getUserHeaders();
    const stateModel = {
        subModuleId,
        userId,
        state,
    };
    return Axios.post(APIRoute + APIPath.SET_SUBMODULE_STATE, stateModel, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

async function markQuestion(questionId, userId, mark, feedback) {
    const userHeaders = await UserController.getUserHeaders();
    const markModel = {
        questionId,
        userId,
        mark,
        feedback,
    };
    return Axios.post(APIRoute + APIPath.MARK_QUESTION, markModel, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    });
}

export default ModuleController;