import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ModuleController from '../../controllers/ModuleController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Colours } from '../../helpers/Colours';
import { isNullOrUndefined, replaceQueryParam } from '../../helpers/Utils';
import { makeStyles, Typography, Grid, Button, Card, Avatar, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { SpacedContainer } from '../../components/Common/SpacedContainer';
import { Alert } from '../../components/Common/Alert';
import ClearIcon from '@material-ui/icons/Clear';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import EditIcon from '@material-ui/icons/Edit';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AdminController from '../../controllers/AdminController';

const useStyles = makeStyles(theme => ({
    email: {
        [theme.breakpoints.down("xs")]: {
            fontSize: 24,
        }
    },
    moduleCard: {
        position: 'relative',
        height: 110,
        padding: '16px 62px 16px 21px',
        cursor: 'pointer',
        '& h5': {
            marginTop: 20,
        },
        '& .strip': {
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: 5,
        },
        '& .marks': {
            position: 'absolute',
            color: Colours.bodyText,
            top: 16,
            left: 21,
        },
        '& .MuiAvatar-root': {
            position: 'absolute',
            right: 16,
            top: 16,
        },
        '&:hover': {
            backgroundColor: Colours.bg_grey_1,
        }
    },
    navButtons: {
        position: 'fixed',
        padding: "16px !important",
        top: 0,
        left: 56,
        maxWidth: 382,
        minWidth: 382,
        [theme.breakpoints.down("lg")]: {
            maxWidth: 'unset',
            minWidth: 'unset',
            left: 0,
            padding: "8px !important",
            position: 'relative',
        }
    },
    modulesWrapper: {
        marginBottom: 64,
    }
}));

function AdminUserModules(props) {
    const { id, email, userIsBanned } = queryString.parse(props.location.search);
    const { Auth, PushHistory } = props;
    const [modules, setModules] = React.useState([]);
    const [userModules, setUserModules] = React.useState([]);
    const [selectedModule, setSelectedModule] = React.useState(null);
    const [banned, setBanned] = React.useState(userIsBanned === "true");
    const [banShowing, setBanShowing] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [warningText, setWarningText] = React.useState(null);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const classes = useStyles();

    const fetchModuleData = React.useCallback(async () => {
        setWarningText(null);
        setLoading(true);
        const moduleResponse = await ModuleController.getAllModules();
        if (moduleResponse.hasError) {
            setWarningText(moduleResponse.data);
        } else {
            setModules(moduleResponse.data);
        }
        const usersResponse = await ModuleController.getUsersModules(id);
        if (usersResponse.hasError) {
            setWarningText(moduleResponse.data);
        } else {
            setUserModules(usersResponse.data);
        }
        setLoading(false);
    }, [id]);

    // initialise
    React.useEffect(() => {
        async function init() {
            if (!Auth.isAuthenticated) {
                setRedirectUrl('/Login');
                return;
            }
            await fetchModuleData();
        }
        init();
    }, [Auth, fetchModuleData]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [PushHistory, redirectUrl]);

    function getModuleStatus(module) {
        if (isNullOrUndefined(module)) {
            return { colour: Colours.bg_grey_2, status: "Not Assigned", icon: <ClearIcon/> };
        }
        const { isComplete, isStarted } = module;
        if (isComplete) {
            return { colour: Colours.secondary, status: "Completed", icon: <DoneAllIcon/> };
        }
        if (isStarted) {
            return { colour: Colours.primary, status: "Started", icon: <EditIcon/> };
        }
        return { colour: Colours.pink, status: "Assigned", icon: <AssignmentLateIcon/> };
    }

    async function handleToggleBan() {
        setLoading(true);
        setWarningText(null);
        const response = banned
            ? await AdminController.unBanUser(id)
            : await AdminController.banUser(id);
        if (response.hasError) {
            setWarningText(response.data);
        } else {
            replaceQueryParam('userIsBanned', !banned, '/AdminUserModules');
            setBanned(!banned);
        }
        setBanShowing(false);
        setLoading(false);
    }

    async function handleAssignModule() {
        setLoading(true);
        setWarningText(null);
        const response = await ModuleController.addUserToModule(id, selectedModule.id);
        if (response.hasError) {
            setWarningText(response.data);
            setLoading(false);
        } else {
            await fetchModuleData();
        }
        setSelectedModule(null);
    }

    function buildModuleTile(module) {
        const { name, id: moduleId, maximumMark } = module;
        const userModule = userModules.filter(e => e.id === moduleId)[0];
        const { colour, status, icon } = getModuleStatus(userModule);
        const onClick = () => {
            if (isNullOrUndefined(userModule)) {
                setSelectedModule(module);
                return;
            }
            setRedirectUrl(`/AdminUserSections?id=${id}&module=${moduleId}&name=${name}&email=${email}`);
        };
        return <Grid item xs={12} key={moduleId}>
            <Card elevation={3} className={classes.moduleCard} onClick={onClick}>
                <div className="strip" style={{ backgroundColor: colour }}></div>
                <Typography variant="caption" className="marks">
                    {userModule?.currentMark ?? 0}/{maximumMark}{maximumMark === 1 ? '' : ' total'} possible mark{maximumMark === 1 ? '' : 's'}
                </Typography>
                <Typography variant="h5" gutterBottom>
                    {name}
                </Typography>
                <Typography variant="button" style={{ color: colour }}>
                    {status}
                </Typography>
                <Avatar style={{ backgroundColor: colour }}>
                    {icon}
                </Avatar>
            </Card>
        </Grid>
    }

    function buildAssignModuleDialog() {
        const name = selectedModule?.name ?? 'no name';
        return <Dialog open={!isNullOrUndefined(selectedModule)} onClose={() => setSelectedModule(null)}>
            <DialogTitle>Confirm Assignment</DialogTitle>
            <DialogContent>
                Are you sure you want to assign <b>{name}</b> to <b>{email}</b>?
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={loading} onClick={() => handleAssignModule()}>
                    Assign
                </Button>
                <Button color="primary" onClick={() => setSelectedModule(null)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    function buildBanUserDialog() {
        const change = banned ? 'Un-Ban' : 'Ban';
        return <Dialog open={banShowing} onClick={() => setBanShowing(false)}>
            <DialogTitle>{change} {email}?</DialogTitle>
            <DialogContent>
                Are you sure you want to {change.toLowerCase()} {email}? <br/><br/>
                <b>This user will not be able to sign in while banned.</b>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={loading} onClick={() => handleToggleBan()}>
                    {change}
                </Button>
                <Button color="primary" onClick={() => setBanShowing(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    return <SpacedContainer maxWidth="md">
        <LoadingOverlay loading={loading}/>

        <Grid container spacing={2} className={classes.modulesWrapper}>
            <Grid item xs={12}>
                <Typography variant="h2" gutterBottom> 
                    Manage Modules
                </Typography>
                <Typography variant="h4" gutterBottom className={classes.email}> 
                    {email}
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.navButtons}>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={4} sm={5} xs={12}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setRedirectUrl('/Admin')}
                            fullWidth
                        >
                            Back to Dashboard
                        </Button>
                    </Grid>
                    <Grid item xl={12} lg={4} sm={5} xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setBanShowing(true)}
                            fullWidth
                        >
                            {banned ? 'Un-Ban User' : 'Ban User'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Alert severity="warning" text={warningText}/>
            </Grid>

            {modules.map(buildModuleTile)}

            {buildAssignModuleDialog()}
            {buildBanUserDialog()}
        </Grid>
    </SpacedContainer>;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
})
const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminUserModules)

AdminUserModules.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    location: PropTypes.object,
};
