import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, useTheme, useMediaQuery, Grid, Typography, Checkbox } from "@material-ui/core";
import AdminController from "../../controllers/AdminController";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";
import { isNullOrEmpty } from "../../helpers/Utils";
import { WarningDialog } from "../../components/Common/WarningDialog";

const useStyles = makeStyles((theme) => ({
    input: {
        width: "100%",
        minWidth: 300,
        [theme.breakpoints.down("xs")]: {
            minWidth: "unset",
        },
    },
    actions: {
        justifyContent: "space-between",
    },
    buttonsWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
}));

export function AdminAddEditUser(props) {
    const { open, onClose, modules, magicLink, onRemoveMagicLink } = props;
    const [email, setEmail] = React.useState("");
    const [moduleIds, setModuleIds] = React.useState([]);
    const [submitting, setSubmitting] = React.useState(false);
    const [magicLinkResent, setMagicLinkResent] = React.useState(false);
    const [warningText, setWarningText] = React.useState(null);
    const [isMagicLink, setIsMagicLink] = React.useState(false);

    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("xs"));

    async function handleInviteUser(event) {
        event.preventDefault();
        setSubmitting(true);
        setWarningText(null);
        const response = await AdminController.createMagicLinks([email], moduleIds);
        if (response.hasError) {
            setWarningText(response.data);
        } else {
            setEmail("");
            setModuleIds([]);
            onClose(true);
        }
        setSubmitting(false);
    }

    async function handleSaveMagicLink(event) {
        event.preventDefault();
        setSubmitting(true);
        setWarningText(null);
        const response = await AdminController.updateMagicLink(magicLink.id, moduleIds);
        if (response.hasError) {
            setWarningText(response.data);
        } else {
            onClose(true);
        }
        setSubmitting(false);
    }

    async function handleRemoveMagicLink() {
        setSubmitting(true);
        setWarningText(null);
        const response = await AdminController.removeMagicLink(magicLink.id);
        if (response.hasError) {
            setWarningText(response.data);
        } else {
            onRemoveMagicLink(magicLink.id);
            onClose(true);
        }
        setSubmitting(false);
    }

    async function handleResendMagicLink() {
        setSubmitting(true);
        setWarningText(null);
        const response = await AdminController.resendMagicLink(magicLink.id);
        if (response.hasError) {
            setWarningText(response.data);
        } else {
            setMagicLinkResent(true);
        }
        setSubmitting(false);
    }

    React.useEffect(() => {
        setModuleIds([]);
        if (!isNullOrEmpty(magicLink)) {
            setModuleIds(magicLink?.modules);
        }
        setIsMagicLink(!isNullOrEmpty(magicLink));
    }, [magicLink]);

    function handleChange(e, value) {
        setModuleIds(value);
    }

    function handleInput(event) {
        const { name, value } = event.target;
        switch (name) {
            case "email":
                setEmail(value);
                break;
            default:
                break;
        }
    }

    function buildResendSuccessfulDialog() {
        return (
            <Dialog className={classes.root} open={magicLinkResent}>
                <DialogTitle>Email Sent</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">An email has been re-sent to the user to confirm their registration.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setMagicLinkResent(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildAutocomplete() {
        return (
            <Autocomplete
                multiple
                id="modules-autocomplete"
                options={modules.map((el) => el.id)}
                value={moduleIds}
                onChange={handleChange}
                disableCloseOnSelect
                getOptionLabel={(option) => modules.find((el) => el.id === option).name}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox style={{ marginRight: 8 }} checked={isMagicLink ? moduleIds.includes(option) : selected} />
                        {modules.find((el) => el.id === option).name}
                    </React.Fragment>
                )}
                style={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} variant="filled" label="Modules" />}
                ChipProps={{ variant: "outlined" }}
            />
        );
    }

    return (
        <>
            <Dialog open={open} fullWidth={matches}>
                <form onSubmit={isMagicLink ? handleSaveMagicLink : handleInviteUser}>
                    <DialogTitle>{isMagicLink ? magicLink?.email : "Invite User"}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    {isMagicLink
                                        ? "Add or edit the unregistered user's modules below."
                                        : "Enter the email address of the user to invite and assign their modules. This will send them an email containing a unique link to where they can complete their account setup."}
                                </Typography>
                            </Grid>
                            {!isMagicLink && (
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email Address"
                                        placeholder="example@flipside.uk.com"
                                        type="text"
                                        variant="filled"
                                        value={email}
                                        onChange={handleInput}
                                        name="email"
                                        className={classes.input}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {buildAutocomplete()}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={`${isMagicLink && classes.actions}`}>
                        {isMagicLink && (
                            <Button color="primary" onClick={() => handleRemoveMagicLink(magicLink.id)} startIcon={<DeleteIcon />} disabled={submitting}>
                                Delete
                            </Button>
                        )}
                        <div>
                            {isMagicLink && (
                                <Button color="primary" onClick={() => handleResendMagicLink()} disabled={submitting}>
                                    Resend Invite
                                </Button>
                            )}
                            <Button color="primary" type="submit" disabled={submitting}>
                                {isMagicLink ? "Save" : "Invite User"}
                            </Button>
                            <Button color="primary" onClick={() => onClose(false)}>
                                Close
                            </Button>
                        </div>
                    </DialogActions>
                </form>
            </Dialog>
            <WarningDialog warning={warningText} onDismiss={() => setWarningText(null)} />
            {buildResendSuccessfulDialog()}
        </>
    );
}

AdminAddEditUser.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isMagicLink: PropTypes.bool.isRequired,
    magicLink: PropTypes.object,
    modules: PropTypes.array,
    onRemoveMagicLink: PropTypes.func,
};
