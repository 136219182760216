import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserController from '../controllers/UserController';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Role } from '../helpers/Constants';
import { PrivateRoute } from '../components/Common/PrivateRoute';
import { Layout } from '../domain/Layout/Layout';
import { LogIn, LogOut } from '../stores/Actions/Authentication';
import { IndexedDB } from '../helpers/IndexedDB';
import { isNullOrUndefined } from '../helpers/Utils';

// Pages
import Home from '../domain/Home/Home';
import Login from '../domain/Auth/Login';
import Logout from '../domain/Auth/Logout';
import Profile from '../domain/User/Profile';
import PrivacyPolicy from '../domain/Auth/Privacy';
import EULA from '../domain/Auth/EULA';
import PasswordChange from '../domain/User/PasswordChange';
import EmailConfirm from '../domain/User/EmailConfirm';
import EmailChange from '../domain/User/EmailChange';
import EmailChangeConfirm from '../domain/User/EmailChangeConfirm';
import PasswordForgot from '../domain/User/PasswordForgot';
import PasswordReset from '../domain/User/PasswordReset';
import TwoFactorManager from '../domain/User/TwoFactorManager';
import TwoFactorVerify from '../domain/User/TwoFactorVerify';
import NotFound from '../domain/Error/NotFound';
import NotificationList from '../domain/User/NotificationList';
import Admin from '../domain/Admin/Admin';
import Register from '../domain/Auth/Register';
import AdminUserModules from '../domain/Admin/AdminUserModules';
import AdminUserSubmodules from '../domain/Admin/AdminUserSubmodules';
import Course from '../domain/Course/Course';
import CourseModule from '../domain/Course/CourseModule';
import CourseSubmodule from '../domain/Course/CourseSubmodule';
import CourseImageViewer from '../domain/Course/CourseImageViewer';
import AdminUserSubmoduleMarking from '../domain/Admin/AdminUserSubmoduleMarking';
import Guide from '../domain/Home/Guide';
import CMSModules from '../domain/CMS/CMSModules';
import CMSSubmodules from '../domain/CMS/CMSSubmodules';
import CMSSubmodule from '../domain/CMS/CMSSubmodule';
import CMSSubmodulePreview from '../domain/CMS/CMSSubmodulePreview';

class App extends Component {
    constructor(props) {
        super(props);
        this.initTokenStore(props);
    }

    initTokenStore = async (props) => {
        // initialise local data store
        const init = await IndexedDB.init();
        if (init.hasError) {
            console.warn(init.data);
            return;
        }
        // check if existing token is valid
        if (await UserController.hasTokenExpired()) {
            props.LogOut();
            return;
        }
        // check for existing token
        const userData = await UserController.fetchCachedUserData();
        if (userData.hasError) {
            console.warn(userData.data);
            props.LogOut();
            return;
        }
        // save cached data if any 
        const { token, userName, role } = userData.data;
        if (!isNullOrUndefined(token) && token !== "null") {
            props.LogIn({
                userName,
                role,
                isLoggingIn: false,
                isAuthenticated: true,
            });   
        } else {
            props.LogOut();
        }
    }

    render() {
        if (this.props.Auth.isLoggingIn) {
            return (<div />);
        }
        return (
            <Layout>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/Guide' component={Guide} />
                    <Route path='/Login' component={Login} />
                    <Route path='/Privacy' component={PrivacyPolicy} />
                    <Route path='/EULA' component={EULA} />
                    <Route path='/MagicRegistration' component={Register} />
                    <Route path='/Logout' component={Logout} />
                    <Route path='/Notifications' component={NotificationList} />
                    <Route path='/UserManagement' component={Profile} />
                    <Route path='/ChangePassword' component={PasswordChange} />
                    <Route path='/EmailConfirmation' component={EmailConfirm} />
                    <Route path='/ChangeEmail' component={EmailChange} />
                    <Route path='/ChangeEmailConfirmation' component={EmailChangeConfirm} />
                    <Route path='/ForgotPassword' component={PasswordForgot} />
                    <Route path='/ResetPassword' component={PasswordReset} />
                    <Route path='/ManageTwoFactor' component={TwoFactorManager} />
                    <Route path='/VerifyTwoFactor' component={TwoFactorVerify} />
                    <Route path='/Course' component={Course} />
                    <Route path='/CourseModule' component={CourseModule} />
                    <Route path='/CourseSection' component={CourseSubmodule} />
                    <Route path='/CourseImageViewer' component={CourseImageViewer} />
                    <PrivateRoute path='/Admin' role={this.props.Auth.role} roles={[Role.Admin]} component={Admin} />
                    <PrivateRoute path='/AdminUserModules' role={this.props.Auth.role} roles={[Role.Admin]} component={AdminUserModules} />
                    <PrivateRoute path='/AdminUserSections' role={this.props.Auth.role} roles={[Role.Admin]} component={AdminUserSubmodules} />
                    <PrivateRoute path='/AdminUserSubmoduleMarking' role={this.props.Auth.role} roles={[Role.Admin]} component={AdminUserSubmoduleMarking} />
                    <PrivateRoute path='/CMSModules' role={this.props.Auth.role} roles={[Role.Admin]} component={CMSModules} />
                    <PrivateRoute path='/CMSSections' role={this.props.Auth.role} roles={[Role.Admin]} component={CMSSubmodules} />
                    <PrivateRoute path='/CMSSection' role={this.props.Auth.role} roles={[Role.Admin]} component={CMSSubmodule} />
                    <PrivateRoute path='/CMSSectionPreview' role={this.props.Auth.role} roles={[Role.Admin]} component={CMSSubmodulePreview} />
                    <Route path='' component={NotFound} />
                </Switch>
            </Layout>
        );
    }
}

const mapStateToProps = state => ({
    Auth: state.Authentication
});

const mapDispatchToProps = dispatch => ({
    LogIn: data => dispatch(LogIn(data)),
    LogOut: () => dispatch(LogOut())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

App.propTypes = {
    Auth: PropTypes.object,
    LogIn: PropTypes.func,
    LogOut: PropTypes.func,
};