import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, DialogContent, DialogActions, makeStyles, Button, Dialog } from '@material-ui/core';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Alert } from './Alert';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiDialog-paper': {
			backgroundColor: Colours.backgroundDark,
		},
		'& .MuiButton-label': {
			color: Colours.bodyTextLight,
		},
	},
}));

export function WarningDialog(props) {
	const { warning, onDismiss } = props;
	const classes = useStyles();

	return (
		<Dialog className={classes.root} open={!isNullOrUndefined(warning)}>
			<DialogTitle>Error</DialogTitle>
			<DialogContent>
				<Alert header="Sorry, but something went wrong..." text={warning} />
			</DialogContent>
			<DialogActions>
				<Button onClick={onDismiss}>Close</Button>
			</DialogActions>
		</Dialog>
	);
}

WarningDialog.propTypes = {
	warning: PropTypes.any,
	onDismiss: PropTypes.func,
};
